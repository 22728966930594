import React, { useEffect, useMemo, useState } from "react";
import "./stylesheets/AddForas.css"
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useUser } from "../hooks/useUser.js";
import Loading from "./components/Loading.js";
import { useNavigate } from "react-router-dom";

const durations = [
  90, 180, 365
];
const AddJob = () => {
  const navigate = useNavigate()

  const [categories, setCategories] = useState([])
  const [jobTypes, setJobTypes] = useState([])
  const [activeTags, setActiveTags] = useState([])
  const curUser = useUser()
  const minStartDate = useMemo(() => {
    const now = new Date()
    now.setDate(now.getDate() + 1)
    return now.toISOString().split('T')[0]
  }, [])
  const minEndDate = useMemo(() => {
    const now = new Date()
    now.setDate(now.getDate() + 1)
    return now.toISOString().split('T')[0]
  }, [])


  const [startDate, setStartDate] = useState(() => {
    const now = new Date()
    return now.toISOString().split('T')[0]
  })
  const [endDate, setEndDate] = useState(() => {
    const now = new Date()
    now.setMonth(now.getMonth() + 1)
    return now.toISOString().split('T')[0]
  })
  const [isBid, setIsBid] = useState(false)
  const [price, setPrice] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [duration, setDuration] = useState(durations[0]);
  const [city, setCity] = useState('')
  const [jobType, setJobType] = useState('')
  const [description, setDescription] = useState("")
  const [title, setTitle] = useState("")

  const [loading, setLoading] = useState(false)


  const selectedCategoryTags = categories.find((option) => option._id === selectedCategory)?.tags || [];


  function handleDescriptionChange(e) {
      // Replace actual line breaks with '\n' when storing the value
      // setDescription(e.target.value.replace(/\n/g, '\\n'));
      setDescription(e.target.value)
  }

  function renderDescriptionWithLineBreaks(text) {
      // Convert '\n' back into actual line breaks for display
      return text.split('\\n').map((line, index) => (
          <React.Fragment key={index}>
              {line}
              <br />
          </React.Fragment>
      ));
  }

  const fetchOptions = () => {
    if (categories.length > 0) {
      return;
    }
    axios.get(`/categories/`, { params: { getTypes: true, enabled: true } }).then(result => {
      setCategories(result.data?.categories)
      setJobTypes(result.data?.jobTypes)
    }).catch(error => {
      console.log(error)
      setCategories([])
    })
  }

  const generateOptionList = () => {
    return categories.map((option) => (
      <option style={{ marginRight: '10%' }} key={option._id} value={option._id}>
        {option.name}
      </option>
    ))
  }
  const generateCityDropdown = () => {
    const cities = [
      "Cairo",
      "Alexandria",
      "Giza",
      "Shubra El-Kheima",
      "Port Said",
      "Suez",
      "Luxor",
      "al-Mansura",
      "El-Mahalla El-Kubra",
      "Tanta",
      "Asyut",
      "Ismailia",
      "Fayyum",
      "Zagazig",
      "Aswan",
      "Damietta",
      "Damanhur",
      "al-Minya",
      "Beni Suef",
      "Qena",
      "Sohag",
      "Hurghada"
    ];

    return cities.map((city, index) => (
      <option style={{ marginRight: '10%' }} key={index} value={city}>
        {city}
      </option>
    ));
  };

  const generateDuration = () => {

    function formatDaysToMonths(duration) {
      if (duration === 90) {
        return "3 Months";
      } else if (duration === 180) {
        return "6 Months";
      } else if (duration === 365) {
        return "1 Year";
      }
    }

    return durations.map((duration, index) => (
      <option style={{ marginRight: '10%' }} key={index} value={duration}>
        {formatDaysToMonths(duration)}
      </option>
    ));
  };

  const handleClickTag = (event, index) => {
    if (event.target.checked) {
      setActiveTags([...activeTags, index]);
    } else {
      setActiveTags(activeTags.filter((item) => item !== index));
    }
  };


  const generateTags = () => {
    if (selectedCategoryTags.length === 0) {
      return Array.from({ length: 4 }).map((_, index) => (
        <label key={index} id="tag-label" style={{ fontStyle: 'italic', fontSize: '1.75svh', color: '#00000080' }}>
          <input
            type="checkbox"
            disabled
          />
          <label style={{ fontSize: '1svh', textWrap: 'nowrap' }}>Choose Category</label>
        </label>
      ));
    }

    return selectedCategoryTags.map((tag, index) => (
      <label key={index} style={{ fontSize: '1svh', textWrap: 'nowrap' }}>
        <input
          type="checkbox"
          checked={activeTags.includes(index)}
          onChange={(event) => handleClickTag(event, index)}
        />
        {tag}
      </label>
    ));
  };

  const generateJobTypes = () => {
    return Object.entries(jobTypes).map(([value, label]) => (
      <>
        <input id={value} value={value} type="radio" name="jobType" onChange={(e) => setJobType(e.target.value)} />
        <label style={{ fontSize: '1.5svh' }} htmlFor={value}>{label}</label>
      </>
    ));
  }

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.value)
    setActiveTags([])
  }

  function toggleIsBid() {
    setIsBid(prev => !prev)
    setPrice(0)
  }

  function createJob() {
    let missingFields = [];

    if (!isBid && price <= 0) {
        missingFields.push("Price must be greater than 0");
    }

    if (!selectedCategory?.length > 0) {
        missingFields.push("Please select a category");
    }

    if (!jobType?.length > 0) {
        missingFields.push("Please select a type");
    }

    if (!description?.length > 0) {
        missingFields.push("Please enter a description");
    }

    if (!title?.length > 0) {
        missingFields.push("Please enter a title");
    }

    if (duration < 0) {
        missingFields.push("Please select a duration");
    }

    if (!city?.length > 0) {
        missingFields.push("Please select a city");
    }

    if (missingFields.length > 0) {
        alert("You're missing the following fields:\n- " + missingFields.join("\n- "));
        return;
    }

    setLoading(true)
    axios.post(`/jobs/create`, {
      title,
      description,
      isBid,
      price,
      type: jobType,
      city,
      durationDays: duration,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      categoryID: selectedCategory,
      owner: curUser.id,
      tags: activeTags.map(e => selectedCategoryTags[e])
    })
      .then(() => {
        navigate(-1)
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  const LabelWithInfo = ({ labelText, infoText }) => {
    const [showInfo, setShowInfo] = useState(false);
  
    const toggleInfo = () => {
      setShowInfo(!showInfo);
    };
  
    return (
      <div className="label-with-info" style={{ display: 'flex', position: 'relative' }}>
        <label id="TagRowLabel" style={{ marginRight: '.5svh' }}>{labelText}</label>
        <i 
          className="info-icon"
          style={{ cursor: 'pointer', fontSize: '1.25svh' }}
          onMouseEnter={toggleInfo}
          onMouseLeave={toggleInfo}
        >
          ⓘ
        </i>
        <div className={`info-tooltip ${showInfo ? 'visible' : ''}`} style={{ marginLeft: '8px' }}>
          <h5 id="infoText">
            {infoText}
            </h5>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="AddForasDetails">

        {/* <img src={Lineage} /> */}
        <h2 id="AddNewForas">Add New Job</h2>
        <div style={{ marginBottom: '2.5%', marginTop: '1%', }}>
          {generateJobTypes()}
        </div>

        <form style={{ display: 'flex', flexDirection: 'column', columnGap: '5%' }}>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <div id="AddforasRow">
                <LabelWithInfo labelText="Job Title" infoText="Enter the official title of the job." />
              <input value={title} onChange={e => setTitle(e.target.value)} type="text" placeholder="e.g. Foras Frontend Developer" style={{ width: '100%', borderRadius: 5, height: 40, fontSize: 14, borderWidth: .5, padding: '1%', paddingLeft: '2%' }} />
            </div>

            <div id="AddforasRow">
              <label id="TagRowLabel">Department</label>
              <select className="JobCategory" id="cars" value={selectedCategory} onChange={handleCategoryChange}>
                <option style={{}} value="">Choose Department</option>
                {generateOptionList()}
              </select>
              <div className="TagRow" style={{ marginTop: '1.25%' }}>
                {generateTags()}
              </div>
            </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2.5%' }}>

            <div id="AddforasRow">
              <label id="TagRowLabel">Location</label>
              <select className="JobCategory" id="cars" onChange={event => setCity(event.target.value)}>
                <option style={{}} value="">Choose City</option>
                {generateCityDropdown()}
              </select>
            </div>


            <div id="AddforasRow">
              <label id="TagRowLabel">Duration</label>
              <select  disabled={jobType === 'fullTime'} className="JobCategory" id="cars" value={duration} onChange={event => setDuration(event.target.value)}>
                {generateDuration()}
              </select>
            </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2.5%' }}>


            <div id="AddforasRow" style={{flexDirection:'row'}}>
              <div style={{display:'flex', flexDirection:'column', gap:'5%', width:'47.5%'}}>
              <LabelWithInfo labelText="Deadline" infoText="Deadline for recieving applications" />
              <input  value={endDate} onChange={e => setEndDate(e.target.value)} min={minEndDate} type="date" style={{ width: '100%', height: 40, borderRadius: 5, borderWidth: .5, paddingRight: '2%', paddingLeft: '2%' }} />
              </div>
              <div style={{display:'flex', flexDirection:'column', gap:'5%', width:'47.5%', marginLeft:'7.5%'}}>
              <LabelWithInfo labelText="Start Date" infoText="When the chosen applicants will start" />
              <input  value={startDate} onChange={e => setStartDate(e.target.value)} min={minStartDate} type="date" style={{ width: '100%', height: 40, borderRadius: 5, borderWidth: .5, paddingRight: '2%', paddingLeft: '2%' }} />
              </div>
            </div>

            {/* TODO: Style Start Date */}
            {/* <div id="AddforasRow">
              <label id="TagRowLabel">Start Date</label>
              <input  value={startDate} onChange={e => setStartDate(e.target.value)} min={minStartDate} type="date" style={{ width: '90%', height: 40, borderRadius: 5, borderWidth: .5, paddingRight: '2%', paddingLeft: '2%' }} />
            </div> */}

            <div id="AddforasRow">
              <label id="TagRowLabel">Compensation/Salary</label>
              <input id="price"
                disabled={isBid} value={price} onChange={(e) => setPrice(parseInt(e.target.value || 0))}
                type="text" placeholder='Enter Compensation or Salary' style={{ borderRadius: 5, paddingLeft: '2%', height: 40, width: '100%', borderWidth: .5 }} />
              <div id="JobCategory" style={{ marginTop: '1.25%' }}>
                <input type="checkbox" id="isBid" value={isBid} onChange={toggleIsBid} />
                <label id="TagRowSubLabel" htmlFor="isBid" style={{ userSelect: 'none' }}>Not Stated</label>
              </div>
            </div>

          </div>

          <LabelWithInfo labelText="Description" infoText="Short job summary, Key responsibilities, qualification and skills if needed." />
          <textarea
                value={description.replace(/\\n/g, '\n')}  // Replace stored '\\n' with actual line breaks in textarea
                onChange={handleDescriptionChange}
                style={{
                    marginBottom: '2.5%',
                    width: '100%',
                    borderRadius: 10,
                    height: 250,
                    paddingLeft: '2%',
                    paddingTop: '2%',
                    fontSize: 14,
                    resize:'none'
                }}
                placeholder="Description"
            />


        </form>
        <button disabled={loading} className="AddForas" style={{ width: '12.5%', alignSelf: 'flex-end', padding: '1%' }} onClick={createJob}>
          {loading ? <Loading color="white" size={16} /> : "Post"}
        </button>
      </div>
    </>
  )
};

export default function AddForas() {
  return (
    <div className='AddForasPage'>
      <div className='JobBackground'>
        <AddJob />
      </div>

    </div>
  )
}