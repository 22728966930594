import React, { useEffect, useRef, useState } from 'react'
import './stylesheets/ChatPage.css';
import ProfileFreelancer from './pages/stylesheets/imgs/ProfileFreelancer.png'
import { useUser } from './hooks/useUser';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { chatSocket } from '.'
import { useChatSocketEvents } from './hooks/useChatSocketEvents';
import axios from 'axios';
import useChats from './hooks/useChats';
import ProfileImage from './pages/components/ProfileImage';
import Loading from './pages/components/Loading';
import { HiArrowCircleRight } from "react-icons/hi";

const ChatSection = () => {
  return (
    <>

    </>
  )
};

function Contact({ chat, chatterMeta, onClick, isActive }) {
  const name = chatterMeta.firstName + ' ' + chatterMeta.lastName;
  const messageType = chat.latestMessage.content.contentType;
  const message = messageType === 'text' ? chat.latestMessage.content.data : 'Document';
  const fromCurUser = chatterMeta.user === chat.latestMessage.receiver;

  return (
    <div
      className='contact'
      id= {isActive? 'contactscale':'contactnormal'}
      onClick={onClick}
      style={{
        backgroundColor: 'transparent',
        

      }}
    >
      <ProfileImage id={chatterMeta.user} imageURL={chatterMeta.imageURL} size={60} />
      <div style={{ marginLeft: 10 }}>
        <h4 style={{ margin: 5, color: 'black' }}>{name}</h4>
        <h5 style={{ margin: 0, color: 'black' }}>
          {fromCurUser ? "You" : chatterMeta.firstName}: {message.length > 25 ? `${message.substring(0, 27)}...` : message}
        </h5>
      </div>
    </div>
  );
}

export default function ChatPage() {
  const curUser = useUser()
  const [curUserMeta, setCurUserMeta] = useState()
  const [activeUser, setActiveUser] = useState()
  const [messages, setMessages] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const chattingWith = searchParams.get('id')
  const chats = useChats()
  const events = [ //FIXME: Fix this with useCallback if screen gets glitchy
    {
      name: 'newChat',
      handler: function onNewChat(result) {
        let { chat } = result
        const shouldSetActiveUser = result.activeUser
        if (chat) { // If socket returns chat
          if (shouldSetActiveUser) {
            for (let meta of chat.userMeta) {
              if (meta.user != curUser.id) {
                setActiveUser(meta)
              }
            }
          } else {
            chats.addChats([chat])
          }
          return;
        }

        axios.get(`/users/view/${chattingWith}`, { // Get user data in case of starting new chat
          params: {
            requester: curUser.id,
            justName: true
          }
        })
          .then(result => {
            let userMeta = {
              user: result.data._id,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              inChat: false,
              unreadCount: 0,
            }
            if (shouldSetActiveUser) {
              setActiveUser(userMeta)
            } else {
              chats.addChats([{ userMeta }])
            }
          })
          .catch(error => {
            setActiveUser(undefined)
            console.error(error);
          })
      }
    },
    {
      name: 'newChats',
      handler: function onNewChats(result) {
        if (result.unreadOnly) {
          return;
        }
        const { chats: chatList } = result

        chats.addChats(chatList, () => shouldFetchContacts.current = true)
      }
    },
    {
      name: 'messageHistory',
      handler: function onMessageHistory(result) {
        setMessages(prev => {
          if (!prev) { // Checking for null or undefined
            shouldFetchMessages.current = true
            return result
          }

          if (result.length > 0) {
            // Checking for duplicates
            const firstDateRes = new Date(result[0].createdAt)
            const lastDatePrev = new Date(prev[prev.length - 1].createdAt)

            shouldFetchMessages.current = true
            if (firstDateRes < lastDatePrev) {
              return [...prev, ...result]
            }
            return prev
          }
          else {
            shouldFetchMessages.current = false
            return prev
          }
        })
      }
    },
    {
      name: 'newMessage',
      handler: function onNewMessage(message) {
        const chatExists = chats.update(message.sender, message)
        if (!chatExists) {
          chatSocket.emit('indivChatRequested', { chattingWith: message.sender, activeUser: false })
        }

        if (chattingWith === message.sender) {
          setMessages(prev => {
            const tempMessages = [message, ...prev]
            return tempMessages
          })
        }
      }
    },
    {
      name: 'server_error',
      handler: function onServerError(error) {
        console.error(error);
      }
    }
  ]

  var shouldFetchMessages = useRef(true)
  var shouldFetchContacts = useRef(true)

  useChatSocketEvents(events)

  function handleSendMessage(e) {
    e.preventDefault();

    axios.get('/users/refreshToken')
      .catch(error => console.log(error))

    var message = e.target.message.value
    e.target.message.value = ""
    message = message.trim()
    if (message.length === 0) {
      return;
    }

    const content = {
      contentType: 'text',
      data: message
    }
    chatSocket.emit('messageSent', {
      receiver: chattingWith,
      content,
      senderMeta: curUserMeta
    })

    const latestMessage = {
      sender: curUser.id,
      receiver: chattingWith,
      participants: [curUser.id, chattingWith].sort(),
      content,
      createdAt: new Date().toString()
    }

    setMessages(prev => {
      const tempMessages = [latestMessage, ...prev]
      return tempMessages
    })

    const chatExists = chats.update(chattingWith, latestMessage)
    if (!chatExists) {
      chats.addChats([{
        latestMessage,
        userMeta: [activeUser]
      }])
    }

  }

  function fetchContactsOnScroll(e) {
    const div = e.target
    const maxScroll = div.scrollHeight - div.clientHeight - 10
    if (!shouldFetchContacts.current) {
      if (div.scrollTop > maxScroll) {
        div.scrollTop = maxScroll
      }
      return;
    }

    const scrollThreshold = maxScroll - 90

    if (div.scrollTop > scrollThreshold) {
      console.log('fetching older chats');
      // fetch older contacts
      shouldFetchContacts.current = false
      const lastChat = chats.sortedChats[chats.sortedChats.length - 1].latestMessage
      chatSocket.emit('chatsPageRequested', {
        earliestTime: new Date(lastChat.createdAt),
        unreadOnly: false,
        limit: 10
      })
    }

  }

  function genContacts() {
    if (chats.sortedChats.length === 0) {
      return <div id='EmptyMessageSpace'>
        <h3 id='ClickChat'>You have no chats</h3></div>;
    }

    return chats.sortedChats.map(chat => {
      const chatterMeta = chat.userMeta.find(meta => meta.user !== curUser.id);
      const isActive = chatterMeta.user === chattingWith;

      return (
        <Contact
          key={chat._id}
          chat={chat}
          chatterMeta={chatterMeta}
          onClick={e => {
            if (chattingWith === chatterMeta.user) { // Handling clicking on same chat
              return;
            }

            chatSocket.emit('chatExited', { chattingWith });
            setSearchParams({ id: chatterMeta.user }, { replace: true });
          }}
          isActive={isActive}
        />
      );
    });
  }

  function fetchMessagesOnScroll(e) {
    if (!messages) {
      return;
    }
    const div = e.target
    if (!shouldFetchMessages.current) {
      if (div.scrollTop < 10 - div.scrollHeight) {
        div.scrollTop = 10 - div.scrollHeight
      }
      return;
    }
    const maxScroll = -(div.scrollHeight - div.clientHeight)
    const scrollThreshold = maxScroll + Math.floor(div.clientHeight / 2)

    if (div.scrollTop < scrollThreshold) {
      // fetch older messages
      shouldFetchMessages.current = false
      const lastMessage = messages[messages.length - 1]
      chatSocket.emit('messagesPageRequested', { chattingWith, earliestTime: new Date(lastMessage.createdAt) })
    }
  }


  function genMessageSpace() {
    if (!activeUser) {
      return <div id='EmptyMessageSpace'>
        <h1 id='EmptyMessageLogo'>FORAS</h1>
        <h3 id='ClickChat'>Click on a chat to view messages</h3>
      </div>
    }

    var content = <Loading />
    
    if (messages) {
      content = <>{messages.map((msg, index, arr) => {
        let isCurSender = msg.sender == curUser.id
        let messageType = msg.content.contentType
        let messageText = messageType === 'text' ? msg.content.data : 'Document'
        
        return (
          <div className={`TextSent${!isCurSender ? "2" : ""}`} key={msg._id}>
            <h4 className={`TextSentText${!isCurSender ? "2" : ""}`} key={msg._id}>{messageText}</h4>
            <h5 className={`TimeStampSent${!isCurSender ? "2" : ""}`} key={msg._id}>{new Date(msg.updatedAt).toLocaleString('en-eg',
              {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit'
              })}</h5>
          </div>
        )
      })}</>
    }

    return (
      <>
        <div id='TopBar'>
          <ProfileImage id={activeUser.user} imageURL={activeUser.imageURL} size={40} />

          <h4 id='TopBarCredentials'>
            {activeUser.firstName} {activeUser.lastName}
          </h4>
        </div>
        <div className='MessageSpace' onScroll={fetchMessagesOnScroll}>
          {content}
        </div>
        <div className='HandleSubmitMessage'>
          <form onSubmit={handleSendMessage}>
            <textarea
              style={{resize:'none'}}
              placeholder='Type A Message...'
              disabled={!messages}
              id='message'
              type='text'
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  document.getElementById('submitMessage').click();
                }
              }}
            ></textarea>
            <button
              type='submit'
              id='submitMessage'
              disabled={!messages}
              style={{

              }}>
              <HiArrowCircleRight size={24} />
            </button>
          </form>
        </div>

      </>
    )

  }

  useEffect(() => {
    if (!curUser.loggedIn) {
      navigate('/Login', { state: { from: location.pathname + location.search } })
      return;
    }

    axios.get(`/users/view/${curUser.id}`, { params: { justName: true, requester: curUser.id }, withCredentials: true })
      .then(result => {
        setCurUserMeta(result.data)
      }).catch(error => {
        console.log(error)
      })

    // Requesting initial chats
    chatSocket.emit('chatsPageRequested', { earliestTime: new Date(), unreadOnly: false, limit: 10 })
  }, [curUser.loggedIn, location.pathname, location.search, navigate])

  useEffect(() => {
    function unmountFunc() {
      chatSocket.emit('chatExited', { chattingWith })
    }

    if (!chats.isInit) {
      return;
    }

    shouldFetchMessages.current = true

    if (!chattingWith || chattingWith.length !== 24) {
      setActiveUser(undefined)
      return unmountFunc;
    }
    setMessages(undefined)
    chatSocket.emit('chatEntered', { chattingWith })

    let chat = chats.get(chattingWith)
    if (chat) {
      setActiveUser(chat.userMeta.find(meta => meta.user != curUser.id))
      return unmountFunc;
    }

    chatSocket.emit('indivChatRequested', { chattingWith, activeUser: true })

    return unmountFunc
  }, [chattingWith, chats.isInit])

  return (
    <div className='ChatPage'>
      <div className='ChatWrapper'>
        <div className='ChatDivision'>
          <div className='leftContacts' style={{}} onScroll={fetchContactsOnScroll}>
            <h2 style={{ margin: '1%', padding: '1%', textAlign: 'center', color: 'black', fontSize: '2svh' }}>Active Chats</h2>
            {genContacts()}
          </div>
          <div className='RightMessages' style={{ height: !activeUser ? "95svh" : '85svh' }}>
            {genMessageSpace()}
          </div>

        </div>

      </div>
    </div>
  )
}