import React from 'react';
import './stylesheets/PasswordStrengthIndicator.css'

const PasswordStrengthIndicator = ({ strength }) => {
    // Define the color and text based on the strength prop
    let color = 'darkred';
    let text = 'Too weak';

    switch (strength) {
        case 1:
            color = 'red';
            text = 'Weak';
            break;
        case 2:
            color = '	#FFBF00';
            text = 'Medium';
            break;
        case 3:
            color = 'green';
            text = 'Strong';
            break;
        default:
            break;
    }

    return (
        <div className="password-strength-indicator">
            <div className='strength-bar-outline'><div
                className="strength-bar"
                style={{ backgroundColor: color, width: `${(1 + strength) * 25}%` }}
            ></div></div>
        </div>
    );
};

export default PasswordStrengthIndicator;
