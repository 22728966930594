export const env = 'prod'
export const BACKEND = env === 'prod'?'https://api.foras.site':'http://localhost:8000'
export const PAYMOB_IFRAME_BASE_URL = 'https://accept.paymob.com/api/acceptance/iframes/819849?payment_token='

export const maxUserFileSize = 1024 * 1024 // 1MB
export const maxJobFileSize = 10 * 1024 * 1024 // 10MB
export const validResumeTypes = "application/pdf"
export const validApplicationFileTypes = "application/pdf"
export const validImageTypes = "image/png,image/jpeg,image/webp"

export const userRoles = ['employee', 'employer', 'admin']

export const chatDropdownLimit = 5

export const passwordStrengthThreshold = 2

export const NOTIFS_PER_PAGE = 10
