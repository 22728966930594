import React, { useState } from "react";
import ForasTag from '../components/ForasTag'
import "../stylesheets/AddForas.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Lineage from '../stylesheets/imgs/Linage2.svg'
import { Link } from "react-router-dom";

const AddJob = (JobTitle, JobCategory, JobDuration, JobDesc, JobPrice) => {
  return (
    <>
      <div className="AddForasDetails">
        <center>
          <img src={Lineage} />
        </center>

        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <input type="text" placeholder="Pay With Wallet" style={{ width: '50%', borderRadius: 5, height: 35, fontSize: 14, borderWidth: .25, paddingLeft: 10 }} />
        </form>
        <center>
          <button className="AddForas" style={{ marginTop: 25 }}>
            <Link to="/Confirmation" style={{ textDecoration: 'none', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
              Confirm
            </Link>
          </button>
        </center>
      </div>
    </>
  )
};

const NoCashWallet = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardDate, setCardDate] = useState("");

  const formatCardNumber = (number) => {
    const sanitizedNumber = number.replace(/\D/g, '');
    const lastFour = sanitizedNumber.slice(-4);
    const maskedNumber = lastFour.padStart(sanitizedNumber.length, '*');
    const formattedNumber = maskedNumber.match(/.{1,4}/g)?.join(' ') || maskedNumber;
    return formattedNumber;
  };

  return (
    <div className="NoCashWallet">
      <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute' }}>
        <h5 style={{ fontSize: 10 }}>Can't find your wallet?</h5>
        <button style={{ alignSelf: 'center', marginLeft: 5, fontSize: 10 }}>Refresh</button>
      </div>
      <div className="AddForasDetails">
        <center>
          <img src={Lineage} alt="Lineage" />
        </center>
      </div>
      <div className="card">
        <div className="visa_logo">
          <img src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/visa.png" alt="Visa Logo" />
        </div>
        <div className="visa_info">
          <img src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png" alt="Chip" />
          <p>{formatCardNumber(cardNumber) || "**** **** **** ****"}</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontSize: 8 }}>{cardDate || "MM/YY"}</p>
            <p style={{ fontSize: 8, position: 'absolute', right: 25 }}>{cardName || "FULL NAME"}</p>
          </div>
        </div>
      </div>
      <form style={{ marginTop: 15, flexDirection: 'column', display: 'flex' }}>
        <input
          type="text"
          placeholder="Enter Your Name"
          style={{ width: '50%', borderRadius: 5, height: 35, fontSize: 14, borderWidth: 0.5, paddingLeft: 10 }}
          value={cardName}
          onChange={(e) => setCardName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Credit Card Number"
          style={{ width: '50%', borderRadius: 5, height: 35, fontSize: 14, borderWidth: 0.5, paddingLeft: 10, marginTop: 10 }}
          maxLength="19" // 16 digits + 3 spaces
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          onInput={(e) => {
            const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
            const formattedValue = value.match(/.{1,4}/g)?.join(' ') || value; // Group digits into sets of 4
            e.target.value = formattedValue;
          }}
        />
        <input
          type="text"
          style={{ width: '12.5%', marginTop: 15, height: 35, borderRadius: 5, fontSize: 14, borderWidth: 0.5, paddingLeft: 10 }}
          placeholder="MM/YYYY"
          pattern="\d{2}/\d{4}"
          maxLength="7"
          value={cardDate}
          onChange={(e) => setCardDate(e.target.value)}
          onInput={(e) => {
            const value = e.target.value.replace(/\D/g, '');
            if (value.length > 2) {
              e.target.value = value.slice(0, 2) + '/' + value.slice(2, 6);
            } else {
              e.target.value = value;
            }
          }}
        />
        <input
          type="text"
          placeholder="CVV"
          style={{ width: '12.5%', borderRadius: 5, height: 35, fontSize: 14, borderWidth: 0.5, paddingLeft: 10, marginTop: 15 }}
        />
      </form>
      <div className="AddForasDetails">
      <center>
          <button className="AddForas" style={{ marginTop: 25 }}>
            <Link to="/Confirmation" style={{ textDecoration: 'none', color: 'white', paddingLeft: 10, paddingRight: 10 }}>
              Confirm
            </Link>
          </button>
        </center>
        </div>
    </div>
  );
};


export default function BillingPage() {
  return (
    <div className='AddForasPage'>
      <ForasTag
        firsttext={"Create a "}
        secondtext={"It only takes 3 minutes"}
      />
      <div className='JobBackground'>
        <NoCashWallet />
      </div>

    </div>
  )
}