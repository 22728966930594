import axios from "axios"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useUser } from "../../hooks/useUser.js"
import Loading from "../components/Loading.js"
import { PAYMOB_IFRAME_BASE_URL } from "../../config.js"
import './AddCredits.css'
import Logo from '../stylesheets/imgs/logo.png';

/**
 * 
 * @param {import("react").InputHTMLAttributes<HTMLInputElement> & {label: string}} param0 
 * @returns 
 */
function FieldInput({ label, onChange, value, type }) {
    return <div id="GetCreditsInputWrapper">
        <label>{label} <br />
            <input value={value} onChange={onChange} type={type} id="GetCreditsInput" />
        </label>
    </div>
}

export default function AddCredits() {
    const [amount, setAmount] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams({ pageState: 0 })
    const pageState = parseInt(searchParams.get('pageState')) || 0
    const [billingData, setBillingData] = useState({})
    const [paymentURL, setPaymentURL] = useState()
    const [loading, setLoading] = useState(false)

    const curUser = useUser()

    const navigate = useNavigate()

    // ! Do not use 'useMemo' for these without adding the functions to dependency list
    const nextButtonFunctions = [
        goForward, goForward, initPayment
    ]
    const nextButtonLabels = [
        'Next', 'Next', 'Confirm'
    ]

    function goBack() {
        // setPageState(prev => prev - 1)
        navigate(-1)
    }

    function goForward(event) {
        event.preventDefault()

        // Validation for "Enter Amount" state
        if (pageState === 0) {
            if (!amount || amount < 10) {
                alert("Amount must be 10 EGP or greater")
                return;
            }
        }

        // Validation for Billing Info
        if (pageState === 1) {
            const requiredFields = ['first_name', 'last_name', 'email', 'phone_number']
            try {
                requiredFields.forEach(e => { if (!billingData[e]?.trim().length > 0) { throw new Error(`${e} cannot be empty`) } })
            } catch (error) {
                alert(error.message)
                return;
            }
        }

        setPageState(prev => prev + 1)
    }

    function setPageState(val) {
        if (typeof val === 'function') {
            setSearchParams((prev) => {
                return { pageState: val(parseInt(prev.get('pageState')) || pageState) }
            })
            return;
        }
        setSearchParams(val)
    }

    function initPayment() {
        setLoading(true)
        axios.post('/payments/create', {
            user: curUser.id,
            amount,
            ...billingData
        })
            .then(result => {
                // const url = `${PAYMOB_IFRAME_BASE_URL}${result.data}`
                const url = result.data
                setPaymentURL(url)
            })
            .finally(() => setLoading(false))
    }

    function renderCurrentPageState() {

        // Enter Amount
        if (pageState === 0) {
            return <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h4>Please Enter Your Amount <span style={{ fontWeight: 300, fontStyle: 'italic', fontSize: '1.25svh' }}>/EGP</span></h4>
                <form onSubmit={nextButtonFunctions[pageState]} style={{ width: '75%' }}>
                    <input style={{ padding: 10, borderWidth: .5, marginRight: 10, width: '100%', borderRadius:10}} type="text" inputMode="numeric" value={amount}
                        onChange={e => {
                            const newVal = e.target.value.replace(/\D/g, '')
                            setAmount(parseInt(newVal))
                        }}
                    />
                    {/* <label style={{ fontWeight: 300, fontStyle: 'italic', fontSize: '1.75svh' }}>EGP</label> */}
                </form></div>
        }

        // TODO: Add remaining fields (apartment, floor, street, building, postal_code, city, country, state)
        // Enter Billing Info
        if (pageState === 1) {
            return <form onSubmit={nextButtonFunctions[pageState]} onKeyDown={e => e.key === 'Enter' && nextButtonFunctions[pageState](e)}>
                <h3 style={{ marginBottom: '12.5%', textAlign: 'center' }}>Billing Information</h3>
                <FieldInput required label='First Name' type="text" value={billingData.first_name} onChange={updateBillingData('first_name')} />
                <FieldInput required label='Last Name' type="text" value={billingData.last_name} onChange={updateBillingData('last_name')} />
                <FieldInput required label='Email' type="email" value={billingData.email} onChange={updateBillingData('email')} />
                <FieldInput required label='Phone Number' type="tel" value={billingData.phone_number} onChange={updateBillingData('phone_number')} />
            </form>
        }



        if (pageState === 2) {
            return (
                <div className="table-container">
                <h3 style={{ marginBottom: '5%', textAlign: 'center' }}>Billing Information</h3>
                    <table className="receipt-table">
                        {/* <thead> */}
                            <tr>
                                <th>Name</th>
                                <th className="value">Value</th>
                            </tr>
                        {/* </thead> */}
                        <tbody>
                            <tr>
                                <td>Amount</td>
                                <td className="value">{amount}</td>
                            </tr>
                          
                            {Object.entries(billingData).map(([key, value]) => {
                                const title = key.split('_').map(e => e[0].toUpperCase() + e.slice(1)).join(' ');
                                return (
                                    <tr key={key}>
                                        <td>{title}</td>
                                        <td className="value">{value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }



    }

    const updateBillingData = (field) => function (e) {
        setBillingData(prev => ({ ...prev, [field]: e.target.value }))
    }

    useEffect(() => {
        if (paymentURL) {
            console.log('paymentToken', paymentURL)

            const a = document.createElement('a')
            a.href = paymentURL
            a.target = "_blank"
            document.body.appendChild(a)
            a.click()

            document.body.removeChild(a)
        }
    }, [paymentURL])

    return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#00000005' }}>
        <div id="PageWrapper">
            {renderCurrentPageState()}
            {/* State Control Buttons */}
            {pageState > 0 && <button id="PageWrapperButton" onClick={goBack}>Go Back</button>}
            <button id="AddCreditsButton" style={{}}
                onClick={nextButtonFunctions[pageState]}>{nextButtonLabels[pageState]}
            </button>
            {loading && <Loading />}
            {paymentURL && <h5>You should be automatically redirected. If not, please <a href={paymentURL} target="_blank">Click Here.</a></h5>}
        </div>

    </div>
}