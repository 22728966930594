import React from 'react'

const ForasTag =({firsttext,afterfirst ,secondtext,midtitle}) =>{
  return <>
     <div style={{marginTop:-30}} className='ForasTag'>
        <div className='TagText'>
          <h3>{firsttext} <span>FORAS </span>{afterfirst}</h3>
          <h4>{secondtext}</h4>
        </div>
      </div>
  </>
}
export default ForasTag;