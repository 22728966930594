import binarySearch from "binary-search";
import { useState } from "react";
import { cloneDeep } from "lodash";
import { useUser } from "./useUser";

export default function useChats() {
    const [sortedChats, setSortedChats] = useState([])
    const [chatIdIndex, setChatIdIndex] = useState({})
    const [isInit, setIsInit] = useState(false)
    const { id: curUserId } = useUser()

    // function init(sorted, forUser){
    //     const index = {}
    //     sorted.forEach(chat => {
    //         const otherMeta = chat.userMeta.find(meta => meta.user != forUser)
    //         index[otherMeta.user] = cloneDeep(chat)
    //     })
    //     setChatIdIndex(index)

    //     setSortedChats(sorted)
    //     setCurUserId(forUser)
    //     setIsInit(true)
    // }

    // function getIndex(chattingWith){
    //     if(!isInit){
    //         return undefined
    //     }
    //     const chatDate = chatIdIndex[chattingWith]

    //     var index = binarySearch(sortedChats, chatDate, function comparator(a, b){
    //         dateA = new Date(a.latestMessage.createdAt)
    //         dateB = new Date(b.latestMessage.createdAt)

    //         return dateB-dateA // To handle the fact that list is sorted from high to low
    //     })

    //     if(index < 0){ // Not found
    //         return undefined
    //     }

    //     // TODO: TEST THIS
    //     // Handling duplicate dates
    //     var otherMeta = sortedChats[index].userMeta.find(meta => meta.user != curUserId)
    //     if(chattingWith == otherMeta.user){ // chosen chat matches search
    //         return index
    //     }

    //     var iterateLeftIndex = index-1
    //     while(sortedChats[iterateLeftIndex]?.latestMessage.createdAt == chatDate && chatDate){

    //         otherMeta = sortedChats[iterateLeftIndex].userMeta.find(meta => meta.user != curUserId)
    //         if(chattingWith == otherMeta.user){
    //             return iterateLeftIndex
    //         }

    //         iterateLeftIndex--
    //     }

    //     var iterateRightIndex = index+1
    //     while(sortedChats[iterateRightIndex]?.latestMessage.createdAt == chatDate && chatDate){
    //         otherMeta = sortedChats[iterateRightIndex].userMeta.find(meta => meta.user != curUserId)
    //         if(chattingWith == otherMeta.user){
    //             return iterateRightIndex
    //         }

    //         iterateRightIndex++
    //     }

    //     return undefined
    // }

    function get(chattingWith) {
        if (!isInit) {
            return undefined
        }
        return chatIdIndex[chattingWith]
    }
    /**
     * @returns true if the latestMessage property in chat was updated, false otherwise
     */
    function setLatestMessage(chat, latestMessage) {

        if (new Date(latestMessage.createdAt) > new Date(chat.latestMessage.createdAt)) {
            chat.latestMessage = cloneDeep(latestMessage)
            return true
        }
        return false
    }

    function getInsertionIndex(array, chat) {
        let insertionIndex = binarySearch(array, chat, function comparator(a, b) {
            const dateA = new Date(a.latestMessage.createdAt)
            const dateB = new Date(b.latestMessage.createdAt)

            return dateB - dateA // Reversed to handle descending sort
        })

        if (insertionIndex < 0) {
            insertionIndex = ~insertionIndex
        }
        return insertionIndex
    }

    function update(chattingWith, latestMessage) {
        if (!isInit) {
            return false
        }

        if (!chatIdIndex[chattingWith]) {
            return false
        }

        setSortedChats(prev => {
            const tempSortedChats = [...prev] // cloning previous array
            const index = tempSortedChats.findIndex(chat =>
                chat.latestMessage.sender == chattingWith || chat.latestMessage.receiver == chattingWith) // finding the relevant chat
            if (index < 0) { // if chatter not found
                return prev
            }
            let [chat] = tempSortedChats.splice(index, 1) // removing it
            setLatestMessage(chat, latestMessage) // setting latest message only if it's later than current message

            // Performing sorted insert
            const insertionIndex = getInsertionIndex(tempSortedChats, chat)
            tempSortedChats.splice(insertionIndex, 0, chat)

            return tempSortedChats
        })

        setChatIdIndex(prev => {
            const tempChatIdIndex = { ...prev }
            if (!tempChatIdIndex[chattingWith]) { // if chatter not found
                return prev
            }
            tempChatIdIndex[chattingWith] = cloneDeep(tempChatIdIndex[chattingWith])
            setLatestMessage(tempChatIdIndex[chattingWith], latestMessage) // setting latest message only if it's later than current message
            return tempChatIdIndex
        })

        return true
    }

    function checkUniqueAndSorted(arr) {
        const indexIfExists = {}
        const result = []
        var needsSort = false

        var prev = Infinity
        arr.forEach(chat => {
            if (!indexIfExists.hasOwnProperty(chat._id)) { // If it doesn't exist
                indexIfExists[chat._id] = result.length
                result.push(chat)

                if (needsSort) { //Skipping order check if sort needed
                    return;
                }

                // Checking if in order
                let createdAt = new Date(chat.latestMessage.createdAt)
                if (createdAt > prev) { // if current greater than previous
                    needsSort = true
                    return
                }

                prev = createdAt // If current not greater than previous
                return;
            }
            // If it exists
            let existingChat = result[indexIfExists[chat._id]]
            const existingWasUpdated = setLatestMessage(existingChat, chat.latestMessage)
            if (existingWasUpdated) {
                needsSort = true
            }

        })

        if (!needsSort) {
            return result
        }

        return result.sort(function compareFn(a, b) {
            const dateA = new Date(a.latestMessage.createdAt)
            const dateB = new Date(b.latestMessage.createdAt)

            return dateB - dateA
        })
    }

    function addChats(sorted, callback) {

        if (sorted.length === 0) {
            setIsInit(true)
            return;
        }

        setChatIdIndex(prev => {
            const tempChatIdIndex = { ...prev }
            sorted.forEach(chat => {
                const otherMeta = chat.userMeta.find(meta => meta.user != curUserId) // Finding the meta of the other user
                if (tempChatIdIndex[otherMeta.user]) { // Updating latest message if chat already exists
                    setLatestMessage(tempChatIdIndex[otherMeta.user], chat.latestMessage)
                    return
                }
                tempChatIdIndex[otherMeta.user] = cloneDeep(chat) // Cloning chat and assigning it if not exists
            })

            return tempChatIdIndex
        })

        setSortedChats(prev => {
            const tempSortedChats = [...prev]

            // Performing sorted insert
            const insertionIndex = getInsertionIndex(tempSortedChats, sorted[0])
            tempSortedChats.splice(insertionIndex, 0, ...sorted)

            if (callback) {
                callback()
            }
            return checkUniqueAndSorted(tempSortedChats)
        })

        setIsInit(true)

    }

    function reset() {
        setChatIdIndex(prev => { return {} })
        setSortedChats(prev => { return [] })
        setIsInit(false)
    }

    function remove(chattingWith) {
        if (!isInit || !chattingWith) {
            return false
        }
        console.log(chatIdIndex);

        if (!chatIdIndex[chattingWith]) {
            return true;
        }

        const chat = chatIdIndex[chattingWith]

        setSortedChats(prev => {
            const tempSortedChats = [...prev]
            const index = getInsertionIndex(tempSortedChats, chat)
            tempSortedChats.splice(index, 1)
            return tempSortedChats
        })

        setChatIdIndex(prev => {
            const tempChatIdIndex = { ...prev }
            delete tempChatIdIndex[chattingWith]
            return tempChatIdIndex
        })

    }


    return { reset, remove, get, update, addChats, sortedChats, isInit }
}