import { Link, useNavigate, useParams } from "react-router-dom"
import { useUser } from "../hooks/useUser"
import { useEffect, useState } from "react"
import axios from "axios"
import Loading from "./components/Loading"
import DownloadLink from "./components/DownloadLink"
import "./stylesheets/Applicants.css"
import AlertModal from "./components/AlertModal"
import { FaRegCheckCircle } from "react-icons/fa"
import { FaRegCircleXmark } from "react-icons/fa6"
import { formatNewLine, listItemsInEnglish } from "../utils/TextUtils.js"
import PlaceholderImg from '../imgs/placeholderimg.png'
import ProfileImage from "./components/ProfileImage.js"
import CustomModal from "./components/CustomModal.js"

function Application({ data, job, curUser, navigate, setAlertMessage, removeFromList }) {
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectReason, setRejectReason] = useState()
    const [rejectLoading, setRejectLoading] = useState(false)
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [acceptModalText, setAcceptModalText] = useState()

    function checkAccept() {
        setAcceptModalText(`Accept ${data.users?.at(0).firstName + ' ' + data.users?.at(0).lastName}'s application?`)
    }
    async function handleAccept() {
        setAcceptLoading(true)
        axios.post('/jobs/applicants/accept', {
            user: curUser.id,
            app: data._id,
            job: job._id
        })
            .then(result => {
                setAcceptModalText(null)
                navigate(-1)
            })
            .catch(error => {
                if (error.response?.data.name === 'NOT_ENOUGH_CREDITS') {
                    setAlertMessage(error.response.data.message)
                    return;
                }
            })
            .finally(() => { setAcceptLoading(false) })
    }

    async function handleReject() {
        setRejectLoading(true)
        axios.delete('/applications/reject', { data: { app: data._id, user: curUser.id, rejectReason } })
            .then(result => {
                if (result.status === 200) {
                    removeFromList()
                }
            })
            .catch(error => console.error(error))
            .finally(() => {
                closeRejectModal()
                setRejectLoading(false)
            })
    }

    function closeRejectModal() {
        setRejectReason(null)
        setShowRejectModal(false)
    }

    {/* {job?.isBid && <h4>Bid: {data.bid} EGP</h4>} */ }
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return <>
        <div id="ApplicantPreview" className={isExpanded ? "expanded" : ""} onClick={toggleExpand}>
            {data?.users?.map(u => {
                return <div className="applicantlink" >
                    <Link to={`/Profile/?userID=${u._id}`} target="_blank" style={{ textDecoration: 'none', color: 'black' }} >
                        <ProfileImage imageURL={u.imageURL} />
                    </Link>
                    <div id="ApplicantsMap">
                        <Link to={`/Profile/?userID=${u._id}`} target="_blank" style={{ textDecoration: 'none', color: 'black' }} >
                            <h4 id="name">{data.contact?.name || u.firstName + ' ' + u.lastName}</h4>
                            {/* <span style={{ fontSize: 12, fontStyle: 'italic', color: 'gray' }}> @{u.username}</span> */}
                        </Link>
                        <h5 style={{}}>Specialist in {listItemsInEnglish(u.tags, 3)}</h5>
                        <h5 className="applicantbio" style={{}}>{u.bio ? u.bio : <br />}</h5>
                    </div>
                    {data.files?.length > 0 &&
                        <div style={{ marginLeft: 'auto' }}>
                            <DownloadLink
                                downloadName={data.files[0].name}
                                url={'/applications/files/download'}
                                requestBody={{ app: data._id, file: data.files[0]._id, user: curUser.id }}
                            >
                                <h4 style={{ fontSize: 12, fontStyle: 'italic', color: '#34aed4' }}>Download CV</h4>
                            </DownloadLink>
                        </div>
                    }
                </div>
            })}
            {/* {data.files?.length > 0 &&
                <div>
                    {data.files.map(e => <DownloadLink
                        downloadName={e.name}
                        url={'/applications/files/download'}
                        requestBody={{ app: data._id, file: e._id, user: curUser.id }}
                    >
                        <h4 style={{ fontSize: 12, fontStyle: 'italic', color: '#34aed4' }}>Download {e.name}</h4>
                    </DownloadLink>)}
                </div>
            } */}
            <div className={`applicantexpand ${isExpanded ? "visible" : ""}`}>
                <h4 className="userinfo">User Info</h4>
                <h5><span>Applicant email:</span> {data.contact?.email}</h5>
                <h5><span>Applicant phone number:</span> {data.contact?.phone}</h5>
            </div>
            <div className="acceptreject">
                <a style={{ backgroundColor: '#C62828' }} onClick={() => setShowRejectModal(true)} id="AcceptApplicant">
                    Reject
                </a>
                <a onClick={checkAccept} id="AcceptApplicant">
                    Accept
                </a>
            </div>
        </div>
        <CustomModal
            show={showRejectModal}
            close={closeRejectModal}
            text={{ content: "Reject Application?" }}
            inputs={[{ value: rejectReason, label: 'Reason', placeholder: "Reason (Optional)", type: 'text', onChange: (e) => setRejectReason(e.target.value) }]}
            buttons={rejectLoading ? [] : [{ text: "Cancel", onClick: closeRejectModal }, { text: "Reject", onClick: () => handleReject(), backgroundColor: '#C62828', color: 'white' }]}
            centertext={true}
        >
            {rejectLoading && <Loading size={28} containerHeight='28px' />}
        </CustomModal>
        <CustomModal
            show={!!acceptModalText}
            text={{ content: acceptModalText }}
            close={() => acceptLoading ? null : setAcceptModalText(null)}
            centertext
            buttons={ acceptLoading? [] : [{ text: "Cancel", onClick: () => setAcceptModalText(null) }, { text: "Accept", onClick: () => handleAccept() }]}
        >
            <h4 style={{ textAlign: 'center' }}>A notification will be sent to the candidate with the acceptance</h4>
            {acceptLoading && <Loading size={28} containerHeight='28px' />}
        </CustomModal>
    </>
}

export default function Applicants() {

    const [applicants, setApplicants] = useState()
    const [job, setJob] = useState()

    const [alertMessage, setAlertMessage] = useState()

    // Other hooks
    const curUser = useUser()
    const navigate = useNavigate()
    const { id: jobId } = useParams()

    function removeApplicantFromList(index) {
        return function () {
            setApplicants(prev => {
                const updated = [...prev]
                updated.splice(index, 1)
                return updated
            })
        }
    }

    useEffect(() => {
        if (!jobId || !curUser.id) return;

        axios.get(`/jobs/applicants/${jobId}`, { params: { requester: curUser.id, getFiles: true } })
            .then(result => {
                setApplicants(result.data)
            })

        axios.get(`/jobs/view/${jobId}`)
            .then(result => {
                setJob(result.data.job)
            })
    }, [jobId, curUser.id])

    return (
        <div id="ApplicantsPage">
            <div id="ApplicantsWrapper">
                {!job ? <Loading /> :
                    <div id="LeftApplicants">
                        <div id="ApplicantsTopArea" style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', }}>
                            <h2 id="ApplicantHeader">Applicants for{''}</h2>
                            <h2 id="ApplicantsTitle" style={{ color: '#34AED4' }}>{job.title}</h2>
                        </div>
                        <h3 style={{ fontSize: '1.75svh', marginLeft: '1%', marginBottom: '1%', padding: 0, fontStyle: 'italic', color: '#ababab' }}>Description</h3>
                        <h3 className="ApplicantsDescription" >{formatNewLine(job.description)}</h3>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h3 className="ApplicantsDescription" style={{ color: '#00000080', fontWeight: 600 }}>Payment: {job.isBid ? "Up for bid" : `${job.price} EGP`}</h3>
                        </div>
                    </div>
                }
                {!applicants ? <Loading /> :
                    <div id="ApplicantsLayout">
                        <h3 id="ApplicantDescription" style={{ textAlign: 'center', marginTop: '1%', marginBottom: 0, fontSize: '2.5svh' }}>Applicants</h3>
                        {applicants?.length > 0 ? applicants.map((e, i) =>
                            <div>
                                <Application key={e._id} data={e} job={job} curUser={curUser} navigate={navigate} setAlertMessage={setAlertMessage} removeFromList={removeApplicantFromList(i)} />
                            </div>
                        ) : <h4 style={{ textAlign: 'center', marginTop: '1%', marginBottom: 0, fontSize: '2.5svh', color: '#00000050' }}>No Applicants</h4>}
                    </div>
                }
            </div>
            <AlertModal
                show={!!alertMessage}
                text={alertMessage}
                buttonsVisible={true}
                b1={{ text: "Cancel", onClick: () => setAlertMessage() }}
                b2={{ text: "Add Credits", onClick: () => { navigate('/AddCredits'); setAlertMessage() } }}
            />

        </div>
    )
}