import React, { useContext, useEffect, useRef, useState } from 'react'
import "./stylesheets/Home.css"
import Background from './stylesheets/imgs/background.png'
import GraphicDesign from './stylesheets/imgs/graphicdesign.png'
import KnowForas from './stylesheets/imgs/knowForas.png'
import { Link } from 'react-router-dom'
import CompanyImage1 from '../pages/companies/image 1.png';
import CompanyImage2 from '../pages/companies/image 2.png';
import CompanyImage3 from '../pages/companies/image 3.png';
import CompanyImage4 from '../pages/companies/image 4.png';
import axios from 'axios'
import { SimilarTabs } from './ApplyForas.js'
import { HomeJobs } from './components/HomeJobs.js'
import { useUser } from '../hooks/useUser.js'

const Services = ({ Title, Image }) => {
  return (
    <>
      {/* Link To Foras Page */}
      <Link>
        <div className='Service'>
          <img src={Image} alt="Service_Image" />
          {/* <div className='ServiceCategory'><h3>{Title}</h3></div> */}
        </div>
      </Link>
    </>
  )
}

const suggestedJobLimit = 10

export default function Home() {

  const [forYou, setForYou] = useState()
  const curUser = useUser();
  function scroll(direction) {
    const container = document.getElementById("HomeJobsScrollContainer");
    const scrollAmount = 200; // Adjust this value to control how much it scrolls

    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else {
      container.scrollLeft += scrollAmount;
    }
  }

  useEffect(() => {
    axios.get('/jobs/jobsForYou', { params: { limit: suggestedJobLimit, requester: curUser.id } })
      .then(result => {
        setForYou(result.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [curUser.id]);

  const JobList = ({ }) => {
    const scrollRef = useRef(null);

    const scroll = (scrollOffset) => {
      scrollRef.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
    };

    return (
      <div style={{ position: "relative", width: "100vw", overflow: "hidden" }}>
        {/* Left Arrow Button */}
        <button className='ArrowButton' id='LeftArrowButton'
          onClick={() => scroll(-300)}
        >
          {"<"}
        </button>

        {/* Scrollable Job List */}
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            overflowX: "auto",
            gap: "20px", // Adds space between components
            padding: "10px",
            scrollBehavior: "smooth",
            width: "100%",
          }}
        >
          {forYou?.map((e) => {
            let payment = e?.isBid ? "Up for bid" : `${e?.price} LE`;
            return (
              <HomeJobs
                key={e._id}
                id={e._id}
                title={e.title}
                description={e.description}
                owner={e.owner}
                payment={payment}
              />
            );
          })}
        </div>

        {/* Right Arrow Button */}
        <button className='ArrowButton'
          onClick={() => scroll(300)}
        >
          {">"}
        </button>
      </div>
    );
  };

  return (
    <div className='Home'>
      <div className='HomeIntro'>
        <div id='lefthome'>
          <h1>
            Welcome to<span className='blue' style={{ fontStyle: 'DaysOne' }}> FORAS</span>
          </h1>
          <h3>
            Explore a world of possibilities, connect with leading companies, and take the first step towards your professional journey. Your future starts now let's make it extraordinary together!
          </h3>

          <div className='WrapperHomeButtons'>

            {curUser.loggedIn ?
              ''
              :
              <Link to="/Login_SignUp" className='GetStartedButton'>
                <h3>Get Started</h3>
              </Link>
            }

            <Link to="/Foras" className='GetStartedButton' id='viewForas'>
              <h3>View FORAS</h3>
            </Link>
          </div>
        </div>

        <div id='righthome'>
          <img src={Background} />
        </div>

      </div>

      <div id='DisplayCompanies' style={{ flexDirection: 'column' }}>
        <h2 className='ServiceTitle'>{curUser.loggedIn ? "Jobs For You" : "Recent Jobs"}</h2>
        <JobList />

      </div>
      {/* <h2 className='ServiceTitle'>Our Partners</h2> */}
      <div id='DisplayCompanies' style={{ marginBottom: '5%', padding: 0 }}>
        <div id='PartnersLeft'>
          <h2 className='ServiceTitle' id='PartnersHeader' style={{ padding: 0, margin: 0, marginBottom: '2.5%' }}>Our Partners</h2>
          <h3 id='PartnersText'>Strong partnerships are built on trust and mutual respect. We value our partners not just as allies but as essential contributors to our shared success. Together, we build a foundation that drives innovation and growth, creating lasting relationships grounded in trust.</h3>
        </div>
        <div className='PopularServices'>
          <Services
            Image={CompanyImage1}
          />
          <Services
            Image={CompanyImage4}
          />
          <Services
            Image={CompanyImage3}
          />
        </div>
      </div>


    </div>
  )
}