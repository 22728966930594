import React, { useEffect, useState } from 'react'
import "./stylesheets/Profile.css"
import "./stylesheets/ProfileEmployer.css"
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import Loading from './components/Loading'
import { useUser } from '../hooks/useUser'
import EmployeeProfile from './components/profile/EmployeeProfile'
import EmployerProfile from './components/profile/EmployerProfile'

export default function Profile() {
  const location = useLocation()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  var id = searchParams.get('userID')

  const [user, setUser] = useState(undefined)

  const curUser = useUser()

  useEffect(() => {
    if (!id) {
      id = curUser.id
    }
    if (!id) {
      navigate('/Login', { state: { from: location.pathname + location.search } })
      return;
    }

    setUser()

    axios.get(`/users/view/${id}?requester=${curUser.id}`)
      .then(result => {
        setUser(result.data)
      })
      .catch(error => {
        setUser({})
        console.log(error)
      });
  }, [curUser.loggedIn, id])

  return (

    !user ? <Loading /> :
      user.role == 'employee'?<EmployeeProfile employee={user} curUser={curUser} />:
      user.role == 'employer'?<EmployerProfile employer={user} curUser={curUser} />:<></>


  )
}