import React from 'react'
import '../stylesheets/Importbatch.css'
import About1 from '../stylesheets/imgs/About1_1.png'
import About2 from '../stylesheets/imgs/About1_2.png'
import About3 from '../stylesheets/imgs/About1_3.png'
import About4 from '../stylesheets/imgs/About1_4.png'
import About5 from '../stylesheets/imgs/About2.png'
import About6 from '../stylesheets/imgs/About3.png'

export default function ImageBatch() {
  return (
    <div id='ImageBatch' style={{overflowX:'hidden'}}>
      <div className='row'>
        <img className='image1' src={About1} alt='About1' />
        <img className='image2' src={About2} alt='About2' />
      </div>
      <div className='row'>
        <img className='image3' src={About3} alt='About3' />
        <img className='image4' src={About4} alt='About4' />
      </div>
    </div>
  )
}