import React, { useRef } from 'react';
import './stylesheets/AlertModal.css';

/**
 * CustomModal component to display modal content with dynamic text, inputs, and buttons.
 *
 * @param {{
 *  show: boolean, // Determines if the modal should be visible
 *  close: () => void, // Function to close the modal
 *  text: { id: string, content: string }, // Object for text with an ID and the content to display
 *  inputs: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>[],
 *  buttons: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>[], 
 *  children: React.ReactNode // Additional content to be displayed within the modal
 *  centertext: boolean
 * }} props 
 * @returns {JSX.Element | null} The modal element if `show` is true, otherwise null
 */
export default function CustomModal({ show, close, text, inputs = [], buttons = [], children, centertext = false }) {

    const modalOverlayRef = useRef()
    if (!show) {
        return null;
    }

    return (
        <div ref={modalOverlayRef} className='modal-overlay' onClick={e => e.target === modalOverlayRef.current && close()}>
            <div className="modal" id='custommodal'>
                {/* Text Section */}
                {text && <h4 style = {{textAlign:centertext? "center": ""}} id={text.id}>{text.content}</h4>}
                {/* Inputs Section */}
                {inputs.map((input, index) => (
                    <>
                        <label>
                            {input.label}
                        </label>
                        <input
                            key={index}
                            className='modal-input'
                            {...input}
                        />
                    </>
                ))}
                {/* Additional Content */}
                {children}
                <div className='modalbuttonswrapper'>
                    {/* Buttons Section */}
                    {buttons.map((button, index) => (
                        <button style={{backgroundColor:button.backgroundColor, color:button.color}}
                            key={index}
                            className="modal-button"
                            {...button}
                        >
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
