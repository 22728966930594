import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { useEffect } from "react";

export default function ProtectedRoute({ children, roles }) {
    const curUser = useUser()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (
            !curUser.loggedIn ||
            (roles?.length > 0 && !roles.some(e => curUser.isRole(e)))
        ) {
            navigate('/Login', {state: {from: location.pathname + location.search}, replace: true})
            return;
        }
    },[curUser.loggedIn])
    
    if(!curUser.loggedIn){
        return;
    }
    return children
}