import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";
import Loading from "./components/Loading";
import '../stylesheets/forgetpassword.css'
import ForasLogo from '../imgs/small_logo.png';

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    function handleForgotPassSubmit(e) {
        e.preventDefault()

        if (e.target.email.value.length === 0) {
            return;
        }

        setLoading(true)
        axios.post('/users/forgotPass', { email: e.target.email.value })
            .then(res => navigate('/'))
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
    }
    return (
        <div className="forgetPassword">
            <div className="forgetWrapper">
                <div className="logoforget">
                {/* <img src={ForasLogo}/> */}
                <h2 style={{ margin: 0, textDecoration: 'none', color: '#34AED4', fontWeight: 700, fontStyle: 'italic' }}>Foras</h2>
                </div>
                <h5>Please provide us with your email and we will contact you shortly.</h5>
                <form onSubmit={handleForgotPassSubmit}>
                    <input id='email' type="email" placeholder="Email" />
                    {loading ? <Loading /> : <button type="submit">Submit</button>}
                </form>
            </div>
        </div>
    )
}