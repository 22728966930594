import React from 'react'
import './stylesheets/footer.css';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import Logo from './imgs/forsa_logo.PNG'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="FooterSection">

      <div id='leftFooter'>
          <div className='firstlinks'>
            <Link to='About'>About</Link>
            <Link to='ContactUs'>Contact Us</Link>
            <Link to='TermsCondition'>Terms of Service</Link>
            <Link to='PrivacyPolicy'>Privacy Policy</Link>
          </div>

          <div id='secondlinks'>     
        <h3 id='AllRights'>@ 2023 Foras. All rights reserved.</h3>
            <h3 id='followUs'>Follow us:</h3>
          <div id='socialslinks'>
            <Link className="facebook" to="https://www.facebook.com/profile.php?id=61556603874288" target="_thapa">
              <FaFacebookF size={15} color='white' />
            </Link>
            <Link to="https://www.instagram.com/foras_egy?igsh=MWE3b3Z6aDlsNHNqYg%3D%3D&utm_source=qr" target="_thapa">
              <FaInstagram size={15} color='white' />
            </Link>
            <Link to="https://www.instagram.com/foras_egy?igsh=MWE3b3Z6aDlsNHNqYg%3D%3D&utm_source=qr" target="_thapa">
              <FaLinkedinIn size={15} color='white' />
            </Link>

          </div>
          </div>

      </div>

      <Link id='rightFooter' style={{textDecoration:'none'}} to={"./"}>
          <h1>FORAS</h1>
      </Link>

    </div>
  )
}


// <div className='secondlinks'>
// <a style={{cursor:'none',}}>Check out our socials</a>
// <Link className="facebook" to="https://www.facebook.com/profile.php?id=61556603874288" target="_thapa">
//   <FaFacebookF/>
// </Link>
// <Link to="https://www.instagram.com/foras_egy?igsh=MWE3b3Z6aDlsNHNqYg%3D%3D&utm_source=qr" target="_thapa">
//   <FaInstagram className="instagram"/>
// </Link>
// </div>