import { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "./components/Loading.js";

export default function JobStatus() {
    const [job, setJob] = useState()

    // Other Hooks
    const curUser = useUser()
    const { id: jobId } = useParams()

    useEffect(() => {
        if (!jobId || !curUser.id) return;

        axios.get(`/jobs/viewForOwner`, { params: { user: curUser.id, job: jobId } })
            .then(result => {
                setJob(result.data)
            })

    }, [jobId, curUser.id])

    return (
        <div className="JobStatusPage">
            {!job ? <Loading /> :
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap' }}>
                        <h2 style={{ color: '#34AED4' }}>{job.title}</h2>
                    </div>
                    <h3>{job.description}</h3>
                    <h3>Price: {job.price}</h3>
                </div>
            }

        </div>
    )
}