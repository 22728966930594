import React, { useState } from "react";
import ForasTag from '../components/ForasTag';
import "../stylesheets/AddForas.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Lineage from '../stylesheets/imgs/Linage3.svg';
import { Link } from "react-router-dom";

export default function Confirmation({ GetCategory }) {
    // Assuming GetCategory is an array of strings
    GetCategory = ["Software Engineering", "Web Development", "Project Management"];

    const formatCategories = (categories) => {
        if (categories.length === 0) return '';
        if (categories.length === 1) return categories[0];
        if (categories.length === 2) return categories.join(" and ");
        return categories.slice(0, -1).join(", ") + ", and " + categories[categories.length - 1];
    }

    const formattedCategories = formatCategories(GetCategory);

    return (
        <div className='AddForasPage'>
            <ForasTag
                firsttext={"Create a "}
                secondtext={"It only takes 3 minutes"}
            />
            <div className='JobBackground' style={{paddingBottom:250}}>
                <div className="AddForasDetails">
                    <center>
                        <img src={Lineage} />
                    </center>
                </div>
                <h4 style={{ marginLeft: 20 }}>Thank You For Using Foras!</h4>
                <h4 style={{ marginLeft: 20 }}>
                    Your Job is now available to all <span style={{ color: "#34AED4" }}>{formattedCategories}</span> applicants!
                </h4>
            </div>
        </div>
    )
}
