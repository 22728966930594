exports.calculateTimeAgo = (createdAt)=> {
    const now = new Date()
    var timeSince = (now - new Date(createdAt))/1000
    if(timeSince < 60){
        let val = Math.floor(timeSince)
        return `${val} second${val===1?"":"s"} ago`
    }
    timeSince /= 60
    if(timeSince < 60){
        let val = Math.floor(timeSince)
        return `${val} minute${val===1?"":"s"} ago`
    }
    timeSince /= 60
    if(timeSince < 24){
        let val = Math.floor(timeSince)
        return `${val} hour${val===1?"":"s"} ago`
    }
    timeSince /= 24
    let val = Math.floor(timeSince)
    return `${val} day${val===1?"":"s"} ago`
}