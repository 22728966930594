import React, { useLayoutEffect } from 'react'

export default function PrivacyPolicy() {

  useLayoutEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div style={{paddingTop:'2.5%', paddingLeft:'2.5%', paddingRight:'2.5%', paddingBottom:'2.5%'}}>
        <>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif',
      textAlign: "center"
    }}
  >
    <strong>
      <span style={{ fontSize: 21, lineHeight: "107%" }}>
        Privacy Policy for Foras
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    This Privacy Policy governs the collection, use, maintenance, and disclosure
    of information collected from users ("you" or "your") of the Foras platform
    ("Foras," "we," "us," or "our"). This policy applies to the Foras website,
    mobile applications, and all products and services offered by Foras.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>1. Introduction</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    At Foras, we are committed to protecting your privacy and ensuring the
    security of your personal information. This Privacy Policy outlines the
    types of information we collect, how we use and protect that information,
    and your rights regarding your personal data. By using Foras, you consent to
    the data practices described in this policy.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>2. Information We Collect</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We collect various types of information from users to provide and improve
    our services. This includes:
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Personal identification information: This may include your name, email
    address, password, username, phone number, mailing address, and credit card
    information (if provided).
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    User-generated content: Any information, text, images, or other content you
    voluntarily provide or post on our platform, including job listings, project
    descriptions, and messages.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Usage information: We collect data on how you interact with our platform,
    including the pages you visit, the features you use, and your preferences.
    This may include information about your device, browser type, IP address,
    and referral URLs.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Cookies and tracking technologies: We use cookies, web beacons, and similar
    technologies to enhance your browsing experience, personalize content, and
    gather information about your preferences. Cookies are small files stored on
    your device that allow us to recognize and track your activity on our
    platform. You have the option to manage your cookie preferences through your
    browser settings.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>3. How We Use Your Information</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We use the information we collect for the following purposes:
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Account management: Your personal information is used to create and manage
    your account, including authentication, profile customization, and account
    settings.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Transaction processing: If you make payments or receive payments through our
    platform, we collect and process your payment information securely to
    facilitate transactions.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Communication: We may use your email address or phone number to communicate
    with you regarding your account, transactions, service updates, or
    promotional offers.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Service improvement: We analyze user data, including user journeys,
    feedback, and usage patterns, to identify areas for improvement and enhance
    the functionality, performance, and user experience of our platform.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Personalization: We may use information about your preferences and usage
    patterns to personalize your experience on our platform, including
    recommending jobs, projects, or content that may be of interest to you.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Security and fraud prevention: Your information is used to maintain the
    security and integrity of our platform, detect and prevent fraud,
    unauthorized access, or other illegal activities.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>4. Data Retention</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We retain your personal data for as long as necessary to fulfill the
    purposes outlined in this Privacy Policy, unless a longer retention period
    is required or permitted by law. After account deletion, we may retain
    certain information for legitimate business purposes, compliance with legal
    obligations, or resolution of disputes.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Users have the option to delete or update sensitive information, such as
    credit card details, from their accounts, provided there are no outstanding
    payments or legal requirements associated with their account.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>5. Third-Party Payment Processing</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We utilize third-party payment processors, such as Paymob, to facilitate
    payment transactions. Your payment information is securely handled by these
    third-party processors, and we do not store any payment details on our
    system. However, please review the privacy policies of these third-party
    processors for information on how they handle your data.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>6. Data Sharing and Transfers</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We do not sell, trade, or rent your personal identification information to
    third parties without your consent. Your information may be shared with
    trusted third parties who assist us in operating our platform, conducting
    business, or servicing you, as long as those parties agree to keep this
    information confidential and comply with applicable privacy laws.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    In the event that we transfer your personal data outside of Egypt, we will
    ensure appropriate safeguards are in place to protect your information in
    accordance with applicable data protection laws, including the execution of
    standard contractual clauses or obtaining your explicit consent.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>7. Compliance with Laws</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We are committed to complying with all applicable laws and regulations,
    including those of Egypt. If we change our privacy policy, we will notify
    users and require their agreement to continue using our software. We reserve
    the right to update or revise this Privacy Policy at any time to reflect
    changes in our practices or legal requirements.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>8. Your Rights</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    You have certain rights regarding your personal data, including:
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Right to access: You have the right to request access to the personal data
    we hold about you and receive a copy of that information.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Right to rectification: You have the right to request the correction of any
    inaccuracies in your personal data.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Right to erasure: You have the right to request the deletion of your
    personal data under certain circumstances, such as when it is no longer
    necessary for the purposes for which it was collected.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Right to restrict processing: You have the right to request the restriction
    of processing of your personal data under certain circumstances, such as
    when you contest the accuracy of the data.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    Right to data portability: You have the right to receive your personal data
    in a structured, commonly used, and machine-readable format and to transmit
    that data to another controller.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    To exercise any of these rights, or if you have any questions or concerns
    about our privacy practices, you may contact us at Foras.freelance@gmail.com
    or at our physical address: 41 Sherif Street, Second Floor, Downtown, Cairo.
    You can also reach us by phone at 01220795079.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>9. Contact Us</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    If you have any questions or concerns about our privacy practices, or if you
    wish to exercise your rights regarding your personal data, you may contact
    us at Foras.freelance@gmail.com or at our physical address: 41 Sherif
    Street, Second Floor, Downtown, Cairo. You can also reach us by phone at
    01220795079.
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    <strong>10. Changes to This Policy</strong>
  </p>
  <p
    style={{
      marginTop: "0in",
      marginRight: "0in",
      marginBottom: "8.0pt",
      marginLeft: "0in",
      fontSize: "11.0pt",
      fontFamily: '"Aptos",sans-serif'
    }}
  >
    We reserve the right to update or revise this Privacy Policy at any time.
    Any changes will be posted on this page, and your continued use of Foras
    after the changes will signify your acceptance of those changes.
  </p>
</>

    </div>
  )
}