import React from 'react';
import '../stylesheets/SignupComplete.css';
import { FaEnvelope } from 'react-icons/fa'; // Import an email icon
import { Link } from 'react-router-dom';

export default function SignUpComplete() {
  return (
    <div id='SignUpComplete'>
      <div className="icon-wrapper">
        <FaEnvelope className="email-icon" />
      </div>
      <h3>Sign Up Complete!</h3>
      <h4>Please check your email to verify your account.</h4>
      <p>If you didn't receive an email, check your spam folder</p>
      <Link to='/Login'>Sign In</Link>
    </div>
  );
}
