import { useCookieWatcher } from "@fcannizzaro/react-use-cookie-watcher";
import { useEffect, useState } from "react";
import { Buffer } from 'buffer'
import { userRoles as mainUserRoles } from "../config";

export function useUser(){
    const cookie = useCookieWatcher('token_payload', {checkEvery: 300, valueOnly: true})
    const [loggedIn, setLoggedIn] = useState(!!cookie)

    var payload;
    if (cookie) {
        const payloadB64 = cookie.split('.')[1]
        const json = Buffer.from(payloadB64, 'base64').toString('utf8')
        payload = JSON.parse(json)
    }

    const [id, setId] = useState(payload?.id)
    const [roles, setRoles] = useState(payload?.roles.map(e => e.role))
    const [mainRole, setMainRole] = useState(roles?.find(role => mainUserRoles.includes(role)))

    /**
     * 
     * @param {'employer' | 'employee' | 'admin'} role 
     * @returns {boolean}
     */
    const isRole = function(role){
        return roles?.includes(role)
    }

    useEffect(() => {
        if(!cookie){
            setLoggedIn(false)
            setMainRole(undefined)
            setId(undefined)
            setRoles(undefined)
            return;
        }

        setLoggedIn(true)
        const payloadB64 = cookie.split('.')[1]
        const json = Buffer.from(payloadB64, 'base64').toString('utf8')
        const payload = JSON.parse(json)

        setId(payload.id)
        setRoles(payload.roles.map(e => e.role))
        setMainRole(roles?.find(role => mainUserRoles.includes(role)))

    }, [cookie])

    return{loggedIn, mainRole, roles, id, isRole}
}