import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import './stylesheets/navbar.css'

export default function ChatNavbar() {
  const navigate = useNavigate()
  return (
    <div id='ChatNavbarWrapper' style={{position:'absolute',  padding:'1%', borderBottomRightRadius:40, width:'7.5svh'}}>
        <Link to='/Home' id='ReturnNavbar' style={{textDecoration:'none', display:'flex', alignItems:'center', gap:'5%' }}>
        <FaArrowAltCircleLeft id='ChatNavbarText' size={20}/>
        {/* <h4 id='ChatNavbarText'>Return</h4> */}
        </Link>
    </div>
  )
}