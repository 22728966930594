import { useEffect } from "react";
import { chatSocket } from "..";

export function useChatSocketEvents(events){
    useEffect(() => {
        for(let event of events){
            // if(event.name === 'newMessage'){
            //     console.log(event.handler)
            // }
            chatSocket.on(event.name, event.handler)
        }

        return function (){
            for(let event of events){
                chatSocket.off(event.name, event.handler)
            }
        }
    }, [events])
}