import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useNavbarAndFooter() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/Login';
  const isSignUp = location.pathname === '/Login_SignUp';
  const isNotFoundPage = location.pathname === '/404'; 
  // useEffect(() => {
  //   if (isLoginPage || isSignUp) {
  //     document.body.classList.add('hide-navbar-footer');
  //   } else {
  //     document.body.classList.remove('hide-navbar-footer');
  //   }
  // }, [isLoginPage, isSignUp]);

  return !isLoginPage && !isSignUp && !isNotFoundPage
}