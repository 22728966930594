import React, { useLayoutEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';

const TermsConditions = () => {
    useLayoutEffect(() => {
        window.scrollTo(0,0)
      }, [])
 return(
    <div style={{padding:25}}>
  <p
    dir="RTL"
    style={{
      margin: 10,
      marginBottom:2.5,
      fontSize: 16,
      textAlign: "center",
      background: "white",
      fontWeight:'bold'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 30,
          fontFamily: '"Arial",sans-serif',
          color: "#222222"
        }}
      >
        الشروط والأحكامـ
      </span>
    </strong>
  </p>
  <table
    style={{ border: "none", width: "1.15pt", borderCollapse: "collapse" }}
  >
    <tbody>
      <tr>
        <td style={{ padding: "0in 0in 0in 0in", height: "14.65pt" }} />
      </tr>
    </tbody>
  </table>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      الشروط والأحكامـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;تم تحديثها في 19 ديسمبر 2023. حدة، من أجل استخدام منصتنا الموجودة
      على العنوان (
    </span>
    <span style={{ color: "black" }}>
      <a href="http://www.foras.site/" target="_blank">
        <span
          dir="LTR"
          style={{
            fontSize: 13,
            fontFamily: '"Helvetica Neue"',
            color: "#1155CC"
          }}
        >
          www.foras.site
        </span>
      </a>
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ) ("المنصة") والبرمجيات والخدمات المرتبطة بها.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      1.2 من خلال قبول شروط وأحكام هذه الاتفاقية، فإنك توافق على ما يلي:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. أنك تبلغ من العمر 18 عامًا أو أكثر.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. الالتزام بالاتفاقية والعمليات والإجراءات والإرشادات الموصوفة في المنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. الالتزام بشروط سياسة الخصوصية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. تكون مسؤولاً مالياً عن استخدامك للمنصة وشراء أو تسليم الخدمات.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      هـ. أداء التزاماتك المحددة في أي عقد للخدمات تقبله، ما لم تكن هذه التزامات
      محظورة بموجب القانون أو بموجب هذه الاتفاقية. تحتفظ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحق في رفض الخدمة أو تعليقها أو إنهائها لأي شخص بمقتضى تقديرها
      الخاص، بأي سبب أو بدون سبب.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      2.2 في هذه الاتفاقية، ما لم يتم تحديد خلاف ذلك، يشمل الإشارة إلى:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. الشخص يشمل أي شخص طبيعي، جسم قانوني أو غير قانوني (سواء كان لديه شخصية
      قانونية منفصلة) أو الإشارة إلى وكلاء الشخص الشخصية القانونية وخلفائهم
      والمندوبين المسموح بهم؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. الدواعي أو البنود هي الدواعي أو البنود في هذه الاتفاقية؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. الكلمات التي تشير إلى المفرد تشمل الجمع والعكس؛ و
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. الإشارات إلى التواريخ هي وفقًا للتقويم الميلادي ما لم يُذكر خلاف ذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      2.3 العناوين في هذه الاتفاقية هي للمعلومات فقط ويجب تجاهلها عند
      التفسير.&nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3. إنشاء حساب
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.1 يجب عليك إنشاء حساب لاستخدام المنصة لطلب الخدمات واستلامها وتقديمها
      وتسليمها، وأنت مسؤول عن المعلومات التي تقدمها لإنشاء الحساب وأمان كلمات
      المرور الخاصة به، وعن أي استخدام للحساب.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      إذا أدركت أي استخدام غير مصرح به لكلمة المرور أو الحساب، يجب عليك إبلاغنا
      في أقرب وقت ممكن.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ليست لدى&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أي التزام بتزويدك بحسابات متعددة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.2 قد يطلب&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، عند إنشاء حسابك ومن ثم من وقت لآخر، من المستخدمين تقديم وثائق وأدلة
      ومعلومات أخرى مطلوبة من السلطات التنظيمية بموجب قواعد ولوائح "معرفة
      العميل" ومكافحة غسل الأموال المعمول بها. تأذن لنا بالاحتفاظ ببعض المعلومات
      عنك، والحصول عليها من بعض الأطراف الثالثة المحددة من قبلنا، بما في ذلك
      مكاتب تقدير الائتمان الاستهلاكي ووكالات تقدير الاستهلاك الأخرى، وذلك
      للاستخدام فيما يتعلق باستخدام المنصة. تأذن لنا باستخدام والاحتفاظ بهذه
      المعلومات وفقًا لسياساتنا ولوائحنا. يتحمل المستخدمون بشكل حصري مسؤولية
      تقييم والتحقق من هوية وجديرية المصدر ومحتوى أي اتصالات من الأطراف الثالثة.
      لسنا مسؤولين عن التحقق من أو تقديم أي ضمانات بشأن أي اتصالمن الأطراف
      الثالثة على المنصة، وتُطلقنا من أي مسؤولية تجاه أي اتصالات من الأطراف
      الثالثة التي قد تتلقاها أو أي إجراءات قد تتخذها أو تمتنع عنها نتيجة لأي
      اتصال من الأطراف الثالثة تتلقاه على المنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.3 يجوز لنا إغلاق أو تعليق أو تقييد وصولك إلى حسابك. دون الإخلال بما سبق،
      يجوز لنا إغلاق أو تعليق أو تقييد وصولك إلى حسابك في الحالات التالية:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. إذا تبين لنا أنك قد انتهكت هذه الاتفاقية أو قمت بالتصرف بمخالفة لها أو
      للقانون الخاص بـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. إذا قمت بتقديم عرض أقل من القيمة الفعلية لأي خدمة في محاولة لإعادة
      التفاوض على السعر الفعلي بشكل خاص، لتجنب الرسوم؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. إذا تبين لنا أنك قد انتهكت التزامات قانونية (فعلية أو محتملة)، بما في
      ذلك انتهاك حقوق ملكية فكرية لشخص آخر؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. إذا كنا نشتبه أو لدينا أسباب للاعتقاد أو نتأكد من أنك قد تورطت، أو
      تشارك، في أنشطة احتيالية أو غير قانونية؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      هـ. إذا لم تستجب لطلبات التحقق من الحساب؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      و. إذا لم تكمل عملية التحقق من الحساب عند الطلب في غضون أسبوع واحد من
      تاريخ الطلب؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ز. إذا كنت موضع نظام عقوبات، أو إذا تعيقت علاقاتنا المصرفية والمدفوعات بأي
      شكل من الأشكال من الأعمال التجارية معك؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ح. لإدارة أي خطر خسارة على&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، أو أي شخص آخر؛ أو
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ي. لأسباب مماثلة أخرى.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.4 إذا قمنا بإغلاق حسابك بسبب انتهاكك لهذه الاتفاقية، قد تصبح مسؤولاً
      أيضًا عن رسوم معينة كما هو موضح في هذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.5 تحتفظ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، بناءً على تقديرها الخاص، بالحق في تعليق أو إنهاء حسابك ورفض أي استخدام
      حالي أو مستقبلي للمنصة لأي سبب في أي وقت. سيؤدي مثل هذا الإنهاء إلى إلغاء
      أو حذف حسابك وفقدان وتخليك عن جميع المحتوى في حسابك. تحتفظ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحق في رفض الخدمة لأي شخص لأي سبب في أي وقت.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      3.6 يأذن المستخدمون صراحة لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;باستخدام علاماتهم التجارية / حقوق النسخ / التصميمات / الشعارات
      والملكية الفكرية الأخرى المملوكة و / أو برخصة لهم للتكاثر على المنصة،
      وحسابات المستخدمين في أماكن أخرى يعتبرها&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;ضرورية. يتم الاتفاق والتوضيح صراحة أن كل طرف سيحتفظ بكل الحقوق
      والعنوان والمصلحة في علاماته التجارية والشعارات، وأنه لا يوجد شيء موجود في
      هذه الاتفاقية، ولا استخدام العلامة التجارية / الشعارات في الدعاية أو
      الإعلان أو المواد الترويجية أو غيرها، يمكن تفسيره على أنه يمنح لأي طرف أي
      حق أو عنوان أو مصلحة من أي نوع تجاه علامات الطرف الآخر و / أو الشعارات.
      عند استخدام المنصة، يلتزم المستخدمون بالالتزام بشروط سياسة حقوق الملكية
      وانتهاك حقوق النشر.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;دور
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.1 يتفق المستخدمون على أن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;يعمل كوسيط ومقدم مقدمات فقط. في أي حالة، لا يتحمل&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;مسؤولية أي إجراءات يتخذها العميل أو مقدم الخدمة. يخلي&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;هنا بأي مسؤولية عن أي انتهاك لهذه الاتفاقية من قبل العميل أو مقدم
      الخدمة، أو عن أي إجراءات أو إغفال من قبل العميل أو مقدم الخدمة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.2&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;غير مسؤولة تجاه العميل عن الخدمات التي يقدمها له مقدم الخدمة و/أو
      يسلمها له. يوافق العميل ويقر بأن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لن تكون مسؤولة، بأي حال، تجاه أي طرف آخر، بما في ذلك العميل، عن
      الأضرار المباشرة أو غير المباشرة أو العرضية أو التبعية أو الخاصة أو
      النموذجية الناشئة عن أو فيما يتعلق بأي خدمة، سواء تم إبلاغ العميل بإمكانية
      وجود مثل هذه الأضرار أم لا.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.3 يتفق العميل ومقدم الخدمة على إبلاغ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;على الفور بأي تغيير في علاقتهما. وبشكل خاص، يتعين على العميل ومقدم
      الخدمة إبلاغ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بأي عمل يقوم به مقدم الخدمة للعميل خارج نطاق أو فترة هذه الاتفاقية،
      سواء تم ذلك في إطار اتفاقية استشارية أو اتفاقية توظيف أو غير ذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.4 ستسعى&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لضمان توفر منصتها على مدار 24 ساعة في اليوم، ومع ذلك، لن تكون مسؤولة
      إذا كان الموقع غير متاح في أي وقت أو لأي فترة لأي سبب كان. يمكن تعليق
      الوصول إلى المنصة مؤقتًا ودون إشعار في حالة فشل النظام أو الصيانة أو
      الإصلاح أو لأسباب خارجة عن سيطرة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . قد يحتويمنصة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;على روابط إلى مواقع أخرى خارجة عن سيطرتنا؛ ونحن لسنا مسؤولين عن
      محتوى تلك المواقع. إن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لا تضمن أن تلك الروابط ستعمل في جميع الأوقات، ولا تمارس أي سيطرة على
      توفر المواقع المرتبطة. وبالمثل، يجب ألا يتم اعتبار الإشارات والروابط إلى
      أي من تلك المواقع على أنها تأييد من قبل&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;للآراء المعبر عنها في تلك المواقع أو الخدمات المقدمة من قبل تلك
      المواقع. ونحن لسنا مسؤولين عن سياسات الخصوصية الخاصة بأي مواقع مرتبطة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.5 قد يقوم&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بتوفير تطبيقات وأدوات وميزات جديدة من وقت لآخر عبر المنصة، ويمكن أن
      يتوقف استخدام بعض هذه الميزات على موافقة المستخدمين على شروط إضافية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.6 قد يقوم&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإجراء تحديثات معقولة تجاريًا على المنصة من وقت لآخر. إذا قامت&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإجراء تغيير جوهري على المنصة، ستقوم&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإبلاغ المستخدمين، شريطة أن يكون المستخدمون قد اشتركوا مع&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتلقي مثل تلك التغييرات.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.7 يحق لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;إيقاف توفير المنصة أو أي جزء أو ميزة منها لأي سبب وفي أي وقت دون أن
      تكون ملزمة تجاه المستخدمين.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.8 ستعلن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;إذا كانت تعتزم إيقاف أو إجراء تغييرات تجعل التوافق مع الإصدارات
      السابقة غير ممكن، في أي وقت دون أن تكون ملزمة تجاه المستخدمين.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.9 يسمح&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;للمستخدمين بتسويق خدماتهم وطلب عروض الخدمات عبر المنصة. يتفق
      المستخدمون على أن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لن تكون مسؤولة، تحت أي ظرف، عن أي خسارة أو إصابة أو ضرر للمستخدمين
      أو أي طرف آخر تنشأ عن أي معاملة بموجب هذه الاتفاقية، التي تتم عبر المنصة،
      أو نتيجة لخدمات المستخدمين التي قد تكون غير مطابقة للمواصفات أو تنتهك حقوق
      الملكية الفكرية لأي طرف ثالث. أنت توافق وتقر بأنك ستكون المسؤول الوحيد عن
      أي مطالبات أو أضرار تنشأ عن الخدمات المقدمة و/أو/أو تنفيذها و/أو طلبها
      و/أو استلامها عبر المنصة (بما في ذلك على سبيل المثال لا الحصر الجودة
      والكمية والسعر والقابلية للتسويق لأغراض معينة، أو أي مطالبة أخرى ذات صلة)
      وأنك ستقوم بتعويض&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;وحمايتها من جميع هذه المطالبات والأضرار. بالإضافة إلى ذلك، لن
      تكون&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;مسؤولة عن أي مطالبات أو أضرار تنشأ عن أي إهمال أو سوء سلوك أو تضليل
      من
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      نقدم لكم ملخصًا للبنود المتبقية من النص المقدم:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      4.10 المسؤولية: لن يتحمل&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أي مسؤولية عن خسارة الأرباح أو أي أضرار مباشرة أو غير مباشرة أو
      عرضية أو تبعية أو خاصة أو مثالية قد تنشأ عن موضوع الاتفاقية. وتشمل هذه
      الخسائر فقدان الإيرادات أو الأرباح المتوقعة أو فقدان الأعمال.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      5. حقوق&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      :
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      5.1 إزالة المحتوى: لدى&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;الحق في إزالة أو حظر أو حذف أي نص أو صورة أو رسوم بيانية يقوم
      المستخدمون بتحميلها على المنصة إذا كانت تنتهك القانون أو تخالف شروط
      الاتفاقية أو شروط وأحكام المنصة. في مثل هذه الحالات، يحتفظ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحق في إغلاق حساب المستخدم دون إشعار مسبق أو أي مسؤولية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      5.2 إدراج السياسات: يعتبر أي إخلاء المسؤولية وشروط وأحكام وسياسات الخصوصية
      وشروط الاستخدام التي وضعتها&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;على المنصة جزءًا من الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      5.3 إنهاء الاتفاقية: إذا اعتقد&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أن استخدام المستخدم للمنصة أو الخدمات ينتهك الاتفاقية أو شروط وأحكام
      المنصة أو أي قانون سارٍ، فلديه الحق، بتقديره الخاص أو بناءً على طلب من
      السلطات القانونية أو أمر قضائي، في إنهاء الاتفاقية وإغلاق حساب المستخدم
      وتزويد السلطات القانونية بتفاصيل المستخدم عند الطلب.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6. استخدام المنصة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.1 حساب المستخدم: يجب على المستخدمين أن يمتلكوا حسابًا لاستخدام المنصة
      لتقديم الخدمات. إذا أصبح المستخدم على علم بأي استخدام غير مصرح به لكلمة
      المرور أو الحساب، يجب عليه أن يبلغ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;في أقرب وقت ممكن.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.2 طلبات العروض والعروض: يجب على المستخدمين استخدام المنصة لتقديم طلبات
      العروض والعروض للخدمات.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.3 تقديم الخدمات: يجب على مقدمي الخدمات الذين يتم اختيارهم من قبل العملاء
      لتنفيذ الخدمات أن يلتزموا بالمتطلبات والمواصفات والأسعار المتفق عليها مع
      العميل في الوقت المطلوب.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.4 مراجعة الخدمات: يوافق مقد6.4 مراجعة الخدمات: يوافق مقدمو الخدمات على
      مراجعة وتعديل الخدمات التي لا تلبي الاحتياجات بنفقتهم الخاصة ولا يمكنهم
      مطالبة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالمسؤولية عن ذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.5 مسؤولية المستخدم: يتحمل المستخدمون مسؤولية جميع المحتوى الذي يقومون
      بتنزيله أو استخدامه أو نشره أو مشاركته أو تحميله على المنصة. يجب أيضًا على
      المستخدمين الامتثال للقوانين المعمول بها.&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;غير مسؤولة عن محتوى المستخدم، ولا يجب على المستخدمين تحميل أي محتوى
      قد يتسبب في الضرر أو الإساءة أو الضرر للآخرين أو انتهاك حقوق الملكية
      الفكرية للأطراف الثالثة أو استخدام محظور للمنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      يرجى ملاحظة أن هذا ملخص عام ولا ينبغي اعتباره استشارة قانونية. إذا كان
      لديك أية مخاوف أو أسئلة محددة بخصوص الاتفاقية، فمن المستحسن استشارة خبير
      قانوني.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      وفقًا للأحكام التي قدمتها:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      1. استخدام غير مسموح على المنصة: يُحظر على المستخدمين القيام بالممارسات
      التالية أثناء استخدام المنصة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. انتهاك أي قانون أو تنظيم سارٍ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. رفع المحتوى أو إرساله أو مشاركته بدون وجود الحقوق اللازمة للقيام بذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. رفع المحتوى أو إرساله أو مشاركته الذي ينتهكعلى المستخدمين القيام
      بالممارسات التالية أثناء استخدام المنصة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. انتهاك أي قانون أو تنظيم سارٍ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. رفع المحتوى أو إرساله أو مشاركته بدون وجود الحقوق اللازمة للقيام بذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. رفع المحتوى أو إرساله أو مشاركته الذي ينتهك أي علامة تجارية أو براءة
      اختراع أو سر تجاري أو حقوق النشر أو الشهرة أو الخصوصية أو أي حق آخر
      لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أو أي طرف ثالث.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. إجراء فوترة بطريقة احتيالية أو محاولة القيام بذلك تجاه أي عميل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ه. الانخراط أو التشجيع أو المساعدة في أي نوع من أنواع الاحتيال ببطاقات
      الائتمان أو التقاط المعلومات الشخصية أو الاحتيال عبر البريد أو الممارسات
      الممنوعة المشابهة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      و. إجراء أو المطالبة بالدفع دون نية توفير الخدمات أو تلقيها مقابل الدفع.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ز. رفع أو إرسال محتوى ينتهك القانون أو حقوق الأطراف الثالثة، أو يتضمن
      أنشطة أو بيانات تكون مميزة أو مسيئة أو بذيئة أو مجرمة، أو التي قد تسبب
      إساءة للآخرين على أساس الجنسية أو العرق أو الجنس أو التوجه الجنسي أو
      الديانة أو العقيدة أو الإعاقة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ح. رفع أو إرسال محتوى غير قانوني أو احتيالي أو غير صحيح أو مطاردة أو ترويع
      أو تشهير أو اعتداء أو تهديد أو غير لائق بأي شكل من الأشكال وفقًا
      لتقدير&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;الخاص.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ط. نشر رسائل تدعم أو تبرر أي أنشطة غير قانونية، مثل استخدام المخدرات أو
      القرصنة أو الاختراقات، إلخ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ي. تسبب ضررًا لأعمال&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أو سمعتها أو موظفيها أو أعضائها أو منشآتها، أو لأي شخص آخر أو كيان
      قانوني.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ك. محاولة التقاط أو جمع أو تخزين بيانات عن أطراف ثالثة دون علمهم أو
      موافقتهم.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ل. محاولة فك تشفير أو تجميع أو تفكيك أو إجراء الهندسة العكسية لأي برنامج
      يشكل جزءًا من المنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      م. التنكر أو تحريف انتمائك لأي شخص أو كيان.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ن. استخوفقًا للأحكام التي قدمتها:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      1. استخدام غير مسموح على المنصة: يُحظر على المستخدمين القيام بالممارسات
      التالية أثناء استخدام المنصة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. انتهاك أي قانون أو تنظيم سارٍ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. رفع المحتوى أو إرساله أو مشاركته بدون وجود الحقوق اللازمة للقيام بذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. رفع المحتوى أو إرساله أو مشاركته الذي ينتهك حقوق فوراس أو أي طرف ثالث،
      بما في ذلك العلامات التجارية والبراءات وأسرار التجارة وحقوق النشر وحقوق
      الشهرة وحقوق الخصوصية وحقوق أخرى.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. القيام بفوترة احتيالية أو محاولة القيام بذلك تجاه أي عميل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ه. المشاركة في أو المساعدة في أي نوع من أنواع الاحتيال مثل احتيال بطاقات
      الائتمان أو التقنية أو سرقة الهوية أو الاحتيال عبر البريد أو الممارسات
      المحظورة المماثلة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      و. إجراء أو المطالبة بالدفع دون نية تقديم أو تلقي الخدمات مقابل الدفع.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ز. رفع أو إرسال محتوى ينتهك القانون أو حقوق الأطراف الثالثة، أو يتضمن
      أنشطة أو بيانات تكون مميزة أو مسيئة أو بذيئة أو مشينة أو تسبب إساءة
      للآخرين على أساس الجنسية أو العرق أو الجنس أو التوجه الجنسي أو الديانة أو
      العقيدة أو الإعاقة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ح. رفع أو إرسال محتوى غير قانوني أو احتيالي أو غير صحيح أو مطاردة أو ترويع
      أو تشهير أو اعتداء أو تهديد أو مشين بأي شكل من الأشكال وفقًا لتقدير فوراس
      الحصري.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ط. نشر رسائل تدعم أو تبرر أي أنشطة غير قانونية مثل استخدام المخدرات أو
      القرصنة أو الاختراق، إلخ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ي. تسبب ضر
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.9 أنت تدرك أنه، باستثناء المعلومات أو المنتجات أو الخدمات التي تم
      تحديدها بوضوح على أنها مقدمة من قبل فرصة، فإن المنصة لا تقوم بتشغيل أو
      التحكم أو المصادقة على أي معلومات أو منتجات أو خدمات على المنصة بأي شكل من
      الأشكال. أنت تدرك أيضًا أن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لا يمكنها ولا تضمن أو تضمن أن الملفات المتاحة للتنزيل من خلال النظام
      الأساسي ستكون خالية من العدوى أو الفيروسات أو الفيروسات المتنقلة أو أحصنة
      طروادة أو التعليمات البرمجية الأخرى التي تظهر خصائص ملوثة أو مدمرة. أنت
      مسؤول عن تنفيذ الإجراءات ونقاط التفتيش الكافية لتلبية متطلباتك الخاصة فيما
      يتعلق بدقة إدخال البيانات وإخراجها، والحفاظ على وسيلة خارجية للمنصة لإعادة
      بناء أي بيانات مفقودة. تقع على عاتقك وحدك مسؤولية تقييم دقة واكتمال وفائدة
      جميع الآراء والنصائح والخدمات وغيرها من المعلومات المقدمة من خلال المنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.10 من خلال إنشاء حساب على المنصة، يضمن كل مقدم خدمة ويمثل ويضمن لفرص
      حصوله على جميع التراخيص والتصاريح والتفويضات المطلوبة منه بموجب القوانين
      والتشريعات المعمول بها لتقديم خدماته من خلال المنصة. كما يقر مقدمو الخدمات
      المستقلون على متن الطائرة ويضمنون أنهم يقيمون خارج مصر (وسوف يقدمون إثبات
      الوثيقة اللازمة إذا طلب ذلك). يوافق كل مقدم خدمة بموجب هذا على تعويض
      شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;وحمايتها من أي أضرار أو خسائر أو نفقات أو عقوبات قد يتكبدها أو يتعرض
      لها نتيجة لانتهاك مزود الخدمة للضمان والتمثيل والضمان المذكور. يدرك مقدمو
      الخدمة أنه من بين وسائل الانتصاف الأخرى المتاحة لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، يحق لهم تعليق و/أو إنهاء حسابات مقدمي الخدمة التي تم اكتشاف انتهاكها
      لهذا البند 6.10 على الفور.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      6.11 يوافق المستخدمون على تعويض فرصة والحفاظ عليها من جميع
      المطالبات/الخسائر (بما في ذلك رسوم المحاماة للدفاع/مقاضاة أي قضية) التي قد
      تنشأ ضد فرصة بسبب أفعال/إغفال من جانب المستخدمين فيما يتعلق بالاستخدامات
      المحظورة للمنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      7. المشاريع المتميزة
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      7.1 مع مراعاة ترتيبات الرسوم المطبقة، يجوز للعملاء اختيار الاشتراك في
      مديري الحسابات المقدمين من قبل فرصة، حيث سيقوم مدير مشروع فرصة بدعوة مقدمي
      الخدمة المختارين لتقديم عطاءات لمشروع العميل، نيابة عن العميل. عند تلقي
      عطاءات متعددة، سيقوم مدير مشروع فرصة بإبلاغ العميل بتكلفة المشروع المتوقعة
      ويطلب من العميل سداد مبلغ كافٍ لتغطية النفقات ذات الصلة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      7.2 يتم إبلاغ المستخدمين بأن مديري المشاريع سيتواصلون معهم من خلال رسائل
      المنصة الخاصة المخصصة لمديري المشاريع، ولن يطلبوا أبدًا معلومات سرية مثل
      أرقام الحسابات أو كلمات المرور أو المعلومات الشخصية. إذا كانت لديك أي شكوك
      أو شكوك بشأن الرسائل المستلمة، فلا تتردد في الاتصال بنا للإبلاغ عنها عن
      طريق/من خلال&nbsp;
    </span>
    <span style={{ color: "black" }}>
      <a href="mailto:foras.managment@gmail.com" target="_blank">
        <span
          dir="LTR"
          style={{
            fontSize: 13,
            fontFamily: '"Helvetica Neue"',
            color: "#1155CC"
          }}
        >
          foras.managment@gmail.com
        </span>
      </a>
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      7.3 يتم إبلاغ المستخدمين بأن مدير مشروع فرصة سيمارس الحكم وأفضل الممارسات
      الصناعية لتسهيل ومحاولة حصول العميل على مخرجات المشروع وفقًا للمعايير
      المطلوبة، ومع ذلك، يفهم العملاء أن استخدام خدمة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Premium Project
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لا يعني بأي شكل من الأشكال ، للإشارة إلى أننا نوصي أو نؤيد أي مقدم
      خدمة أو نقدم أي تمثيل فيما يتعلق بمهاراتهم أو خدماتهم. لتجنب الشك، فإن
      الدور الرئيسي لمدير مشروع فرصة في خدمة المشروع المميز هو تسهيل وتنسيق
      تقديم الخدمات من قبل مقدمي الخدمة المختارين وتزويد العملاء بالتسليمات
      المقدمة من مقدمي الخدمة "كما هي"، حيث لا تقدم فرصة أي ضمانات أو إقرارات أو
      موافقات صريحة أو ضمنية على الإطلاق (بما في ذلك على سبيل المثال لا الحصر
      ضمانات الملكية أو عدم الانتهاك، أو الضمانات الضمنية الخاصة بالتسويق أو
      الملاءمة لغرض معين) فيما يتعلق بالخدمات، ولا يجوز لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أن تكون مسؤول عن أي تكلفة أو ضرر ينشأ بشكل مباشر أو غير مباشر عن أي
      معاملة من هذا القبيل. يعفي العملاء شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;تمامًا ويطلقون سراحها إلى الأبد من أي وجميع الالتزامات ويدركون أن
      اختيار المشروع المميز هو قرار تجاري يتم اتخاذه وفقًا لتقدير العميل وحده،
      حيث يتحمل بموجبه المسؤولية الكاملة والمخاطر الناجمة عن استخدامه للخدمة.
      عندما لا يكون من الممكن استبعاد المسؤولية بموجب القانون المعمول به، فإنك
      توافق على أن الحد الأقصى لمسؤولية شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;عن أي مطالبة مقابلة يجب أن يقتصر على مبلغ 4000 جنيه مصري. [.]
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      7.4 يوافق المستخدمون على أن أي فشل في الرد على رسائل "طلب تفويض الدفع"
      و"طلب عام للتأكيد" خلال 14 يومًا يأذن لمدير مشروع فرصة بالمضي قدمًا في
      المشروع دون أي مسؤولية على عاتق فرصة، حيث إن الردود على هذه الرسائل هي
      لأغراض التنسيق فقط. 8. الخدمات والرسوم والعمولات
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.1 باستخدام المنصة ونشر طلب تقديم العطاءات، يوافق العملاء على استخدام
      فرصة لسداد جميع المدفوعات لمقدمي الخدمة الذين يختارهم العملاء من خلال
      المنصة. باستخدام المنصة والرد على طلب تقديم العطاءات، يوافق مقدمو الخدمة
      على استخدام فرصة لتلقي جميع المدفوعات من العملاء الذين يحددون مقدمي الخدمة
      أو الذين يحددهم مقدمو الخدمة على المنصة. يقر المستخدمون بأن شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;تحصل على رسومها من خلال المعاملات التي تتم على المنصة. تخضع الرسوم
      التي يدفعها العميل لضريبة القيمة المضافة، حسب الاقتضاء. يوافق العملاء على
      عدم اتخاذ أي إجراء بشكل مباشر أو غير مباشر للتحايل على هذه الرسوم. إذا طلب
      مزود الخدمة الدفع من العملاء خارج المنصة، يوافق العملاء على إخطار فرصة على
      الفور ويوافق مقدمو الخدمة أيضًا على إخطار فرصة على الفور إذا سعى العميل
      إلى الدفع لهم خارج المنصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.2 يشكل استخدامك لفرص موافقتك على دفع أي مبالغ تسمح لنا بتحصيلها من
      حسابك، وحسب الاقتضاء، من بطاقتك الائتمانية أو حسابك البنكي أو مزود (مزودي)
      الدفع المتوفر على المنصة. وهذه المدفوعات، بمجرد التصريح بها، تصبح نهائية.
      كما أنك تأذن لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بمقاصة أرصدة العملاء ومقدمي الخدمات حسب الضرورة، بما في ذلك، على
      سبيل المثال لا الحصر، خصم اختلافات رسوم الصرف، وخصم التكاليف المتعلقة
      بالمشاريع المتميزة، والمبالغ المستردة، والأرصدة غير المسددة، والتسويات
      الناتجة عن قرارات النزاعات التي تم قبولها من قبل المستخدمين.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.3 تحتفظ شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحق في المطالبة بالتعويض منك، وسوف تقوم بتعويض شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، إذا اكتشفت شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;معاملات خاطئة أو مكررة، أو تلقت شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;رسومًا من شركة بطاقة الائتمان أو البنك أو مزود (مقدمي) الدفع لأي
      عميل لأي سبب من الأسباب. أنت توافق على أن شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لها الحق في الحصول على هذا السداد عن طريق فرض رسوم على حسابك، أو خصم
      مبالغ من المدفوعات أو السحوبات المستقبلية، أو فرض رسوم على بطاقتك
      الائتمانية، أو الحصول على تعويض منك بأي وسيلة قانونية أخرى. يعد الفشل في
      سداد تعويضات رد المبالغ المدفوعة سببًا في إنهاء حسابك، وهو ما لا يخل بحقنا
      في المطالبة بالدفع المستحق بأي طريقة منصوص عليها بموجب هذه الاتفاقية أو
      بموجب القانون. في حالة وجود نشاط دفع مشبوه أو إجراءات غير قانونية أو
      احتيالية، تحتفظ فرصة بالحق في تعليق الدفع بشكل مؤقت أو دائم عبر بطاقتك
      الائتمانية و/أو الاتصال بك أو البنك الذي تتعامل معه أو أي طرف ثالث ذي صلة
      للإبلاغ عن مثل هذا النشاط غير العادي و/أو الحصول على معلومات إضافية، أو
      حجز/تجميد أي صندوق في حالة الاشتباه في وجود مصدر غير قانوني للأموال دون أي
      مسؤولية تجاهك من قبل شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      .
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.4 . يجب أن تكون أي مبالغ مستحقة لشركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;صافية من أي ضرائب أو فوائد أو عمولات بنكية أو وسيطة أو رسوم أو رسوم
      ورسوم أخرى.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.5 إذا لم تتلق فرصة، لأي سبب من الأسباب، أي مبالغ قمت بتفويضها بدفعها
      مقابل خدمات فرصة، فإنك توافق على دفع هذا المبلغ فورًا عند الطلب من قبل
      فرصة. أنت توافق أيضًا على دفع أي رسوم فائدة وأتعاب المحاماة وتكاليف
      التحصيل الأخرى التي تتكبدها شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;في تحصيل المبلغ المصرح به ولكن غير المدفوع منك. في مثل هذه الحالة،
      يجوز لشركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، حسب اختيارها، التوقف عن معالجة أي مدفوعات أخرى قمت بتسديدها وتطبيق أي
      مبالغ تحتفظ بها شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;نيابةً عنك تجاه أي أوجه قصور أو خسائر أو تكاليف تكبدناها نتيجة
      لاستخدامك للخدمة المقدمة. خدمة الدفع أو خدمات فرصة الأخرى. يجوز لنا أيضًا
      تقديم التقارير المناسبة إلى وكالات إعداد التقارير الائتمانية والمؤسسات
      المالية ووكالات الضرائب وسلطات إنفاذ القانون والتعاون
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.6 الدفع إلى فرصة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. ستقوم فرصة بتنفيذ رسوم اشتراك لأي مزود خدمة يقدم مساحة ويب أو يعرض على
      المنصة، باستثناء الوظيفة الأولى، حيث سيتم إعفاء الاشتراك. لن يخضع إجمالي
      الدخل لمزود الخدمة لأية عمولة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. اتفق الطرفان صراحةً على أن تقوم شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بخصم مبلغ المبالغ المذكورة بموجب الفقرة الفرعية (أ) من الدفعة
      المقدمة لحسابات مقدمي الخدمة. ومن المتفق عليه صراحة أيضًا أن التسويات التي
      تتم لحساب مقدمي الخدمة تتم في وقت القبول النهائي من قبل العميل لتقديم
      الخدمة. يجب أن يكون لدى مقدمي الخدمة أي دفعات مستحقة لهم من العملاء متاحة
      للسحب بعد 14 يومًا من تاريخ تسوية المبالغ المستحقة بموجب الفقرة الفرعية
      (أ). بعد طلب السحب وتسليم المبالغ إلى الحساب البنكي لمقدم الخدمة (أو طريقة
      تسوية الدفع المخصصة)، لن تتحمل شركة فرص أي مسؤولية أخرى تجاه مزود الخدمة
      فيما يتعلق بتلك المبالغ. يتحمل مقدمو الخدمة مسؤولية أي ضرائب بما في ذلك
      ضريبة القيمة المضافة المطبقة على المبلغ المدفوع إلى فرصة و/أو أي دخل يتم
      تحقيقه من خلال المنصة، وسيدفع مقدمو الخدمة لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;دون أي تخفيض للضرائب أو المقاصة. ج. قم بتنفيذ التزاماتك على النحو
      المحدد في أي عقد للخدمات التي تقبلها، ما لم تكن هذه الالتزامات محظورة
      بموجب القانون أو بموجب هذه الاتفاقية. تحتفظ شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحق، وفقًا لتقديرها الخاص، في رفض أو تعليق أو إنهاء الخدمة لأي شخص
      لأي سبب أو بدون سبب.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.7 ستقوم شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، وكجزء من إجراءاتها الداخلية، بتسوية جميع الأرصدة وفقًا للجداول المحددة،
      والتي سيتم التعامل معها كجزء لا يتجزأ من هذه الاتفاقية، على أساس ربع سنوي،
      وخلال هذه الفترة يجب أن تسعى إلى تطبيق التصحيحات على الأرصدة، طلب
      التعويضات والمبالغ المستردة وغيرها من التدابير التي تراها مناسبة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.8 تحتفظ فرصة بالحق، ضمن تقديرها الكامل، في تحديد الطريقة التي تتم من
      خلالها معالجة طلب استرداد الأموال للعميل (صاحب العمل). سوف تقوم&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإضافة الأموال إلى المحفظة الافتراضية للعميل فقط. لن يتم منح أي
      مبالغ مستردة للبنوك أو بطاقات الائتمان أو&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      PayPal
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أو أي مؤسسة أخرى. 9. المدفوعات الهامة
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      8.9 لا تفرض فرص رسومًا على العملاء إلا بعد الاتفاق مع مزود الخدمة، تفرض
      فرص على العملاء 5% من الميزانية باستثناء الضرائب والرسوم البنكية
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      9.1 ستوفر المنصة للعملاء خيار إجراء مدفوعات خاضعة للرقابة فيما يتعلق
      بالخدمة من خلال دفعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      .
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      9.2 وفقًا لاتفاقياته مع مزود الخدمة، يمكن للعميل إجراء دفعة رئيسية، والتي
      سيتم قفلها من حساب العميل ولا يمكن لمزود الخدمة المطالبة بها حتى:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. يوافق العميل ومقدم الخدمة على إمكانية المطالبة بالأموال من قبل مقدم
      الخدمة؛ ب. إذا كان هناك نزاع، فقد أنهى العميل ومزود الخدمة عملية حل النزاع
      وتم حل النزاع لصالح مزود الخدمة؛ ج. يأمرنا العميل بالدفع لمزود الخدمة
      مقابل الخدمات التي يؤديها مزود الخدمة فيما يتعلق بمشروع أو مسابقة؛ أمر.
      يقر العميل بأن مزود الخدمة قد أكمل الخدمات بما يرضي العميل ويوافق عليه
      بشكل كامل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      9.3 إذا لم يوافق العميل على منتج عمل مقدم الخدمة، يجوز للأطراف اختيار حل
      المشكلة بموجب عملية حل النزاع.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      9.4 إذا لم نتلق أي تعليمات من العميل فيما يتعلق بالدفعة الرئيسية في غضون
      10 أيام عمل بعد اليوم الذي تم فيه دفع الدفعة الرئيسية ولم يقم العميل
      بتسجيل الدخول إلى حسابه خلال تلك الفترة، فسيتم إلغاء قفل الدفعة الرئيسية و
      صدر مرة أخرى إلى العميل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      10.1 تقدم فرصة خدمات حل النزاعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;للمستخدمين الذين اختاروا استخدام ميزة الدفع&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . أنت توافق وتقر بأن: (1) فرصة لا تقدم خدمات قانونية؛ (2) لن تقدم لك فرصة
      المشورة فيما يتعلق بأي مسائل قانونية؛ و(3) إذا كنت ترغب في الحصول على
      مستشار قانوني، فسوف تسعى للحصول على مستشار قانوني مستقل من المرخص لهم
      بممارسة القانون في ولايتك القضائية. لن تعتمد على&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لأي مستشار من هذا القبيل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      10.2 في حالة وجود نزاع بين العميل ومزود الخدمة فيما يتعلق بإرجاع أو تحرير
      مدفوعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ، يجوز لأي من العميل أو مزود الخدمة اختيار استخدام خدمات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتسوية المنازعات التي تقدمها&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;على النحو المنصوص عليه في سياسة نزاعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . سيقوم العميل و/أو مزود الخدمة بإخطار شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;برغبتهم في معالجة الأمر من خلال خدمات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتسوية المنازعات.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      10.3 أنت توافق على تعويض و(إلى الحد الأقصى الذي يسمح به القانون)
      حماية&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;وأي من الشركات التابعة لنا من أي أضرار أو مسؤولية قد تتعرض لها نتيجة
      استخدام&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone Payments
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;و/أو خدمات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتسوية المنازعات.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11. إدارة الحساب
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.1 قد يكون لديك أموال في حسابك إذا كنت قد دفعت مسبقًا مقابل الرسوم أو
      الرسوم أو مقابل الخدمات التي سيتم تقديمها لك عبر المنصة. إذا كنت مقدم
      خدمة، فقد يكون لديك أموال إذا أكملت الخدمة بنجاح، وتم تحرير الأموال لك.
      هناك أيضًا ظروف سيتم فيها إضافة الأموال إلى حسابك فيما يتعلق ببرنامج تابع
      أو برنامج إحالة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.2 نحتفظ بالأموال الموجودة في حسابك في حساباتنا التشغيلية لدى مؤسسات
      مالية ذات سمعة طيبة. لا نحتفظ بالأموال الموجودة في حسابك بشكل منفصل وقد
      يتم دمجها مع أموال التشغيل العامة لدينا وأموال حسابات المستخدمين الآخرين.
      أنت توافق على أنك لن تتلقى فوائد أو أرباح أخرى على الأموال التي
      تضعها&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;في حسابات مختلطة لصالحك. مقابل استخدامك لمنصة فرصة، فإنك تنقل بشكل
      لا رجعة فيه وتعيين إلى فرصة أي حق ملكية قد يكون لديك في أي مصلحة قد تتراكم
      على الأموال المحتفظ بها في الحسابات المختلطة. ينطبق هذا التنازل فقط على
      الفوائد المكتسبة على أموالك، ولا يوجد في هذه الاتفاقية ما يمنح&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أي حق ملكية لأصل الأموال التي تحتفظ بها مع&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . بالإضافة إلى أو بدلاً من كسب الفائدة على الحسابات المختلطة، قد تحصل فرصة
      على تخفيض في الرسوم أو النفقات المفروضة على الخدمات المصرفية من قبل البنوك
      التي تحتفظ بأموالك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.3 إذا كان حسابك يحتوي على رصيد، ولكن لم يكن به أي نشاط لمدة ستة (6)
      أشهر متتالية على الأقل، فسيتم وضع حسابك في حالة "غير نشط". سوف يقوم
      برنامج&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإعلامك بأن حسابك غير نشط عن طريق إرسال بريد إلكتروني إلى عنوان
      بريدك الإلكتروني المسجل ويمنحك خيار إبقاء حسابك مفتوحًا والحفاظ على الرصيد
      أو سحب الرصيد. إذا لم يكن لحسابك أي نشاط خلال ثلاثين (30) يومًا من هذا
      الإشعار، فسوف تقوم شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;تلقائيًا بخصم رصيد حسابك بالكامل.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.4 إذا كان حسابك يحتوي على مبلغ سلبي من الأموال، فيجوز لنا:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. مقاصة المبلغ السلبي من الأموال بالأموال التي تتلقاها لاحقًا في حسابك؛
      ب. إذا كان لديك أموال بعملات متعددة في حسابك وأصبحت إحدى العملات سلبية لأي
      سبب من الأسباب، فقد نقوم بمقاصة المبلغ السلبي مقابل الأموال التي تحتفظ بها
      في رصيد عملة مختلفة (بسعر الصرف المطبق من قبلنا)؛ ج. عكس المدفوعات التي
      أجريتها من حسابك إلى حسابات المستخدمين الأخرى على المنصة؛ د. خصم المبالغ
      المستحقة لنا من الأموال التي تضيفها أو تستقبلها لاحقًا في حسابك؛ خام. قم
      بتعليق حسابك أو تقييده على الفور حتى يحين الوقت الذي لا يحتوي فيه حسابك
      على مبلغ سلبي.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      في حالة قيامنا بتعويض مبلغ سلبي من الأموال وفقًا لهذا القسم، فقد يتم
      تجميعه مع خصم آخر يخرج من حسابك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.5 تحتفظ فرصة بالحق في تحصيل أي أموال مستحقة لنا بأي وسيلة قانونية أخرى.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      11.6 أنت تقر وتوافق على ما يلي:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. نحن لسنا بنكًا أو مؤسسة مالية أخرى مرخصة ولا نقدم لك خدمات مصرفية أو أي
      خدمات مالية؛ ب. تمثل الأموال الموضحة في حسابك (والتي قد تشمل مدفوعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;و/أو أي دفعة مسبقة من الرسوم والمصاريف التي تدين بها لنا) التزاماتنا
      غير المضمونة تجاهك فيما يتعلق بحقوقك في توجيهنا لتسديد الدفع فيما يتعلق
      بالشراء و بيع خدمات مزود الخدمة من خلال المنصة وتقديم خدمات مزود الخدمة؛
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. وبقدر ما يُطلب منا تحرير الأموال من حسابك لك، ستصبح دائنًا غير مضمون
      لنا حتى يتم دفع هذه الأموال لك؛ د. نحن لا نعمل كوصي أو مؤتمن فيما يتعلق
      بهذه الأموال أو المدفوعات؛ ه. مبلغ الأموال الذي يظهر في حسابك غير مؤمن
      عليه وليس وديعة مضمونة؛&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      F
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . لا يجوز تحميل الأموال في حسابك، أو تحريرها من حسابك إلا من خلالنا ويجب
      عليك فقط استخدام الآليات المتاحة على النظام الأساسي للدفع أو تلقي الأموال
      فيما يتعلق بخدمات مقدم الخدمة؛ ز. سنحتفظ بأموال فيما يتعلق بمبلغ حسابك
      (بما في ذلك مدفوعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ) في حساب نحتفظ به لدى مؤسسة مالية (أو بأي طريقة نقررها وفقًا لتقديرنا
      الخاص من وقت لآخر) ولا يتم فصل هذه الأموال في حساب منفصل؛ و. يجوز لنا خلط
      أموالك مع أموال مستخدمين آخرين وأموالنا الخاصة، ويمكن استخدام هذه الأموال
      المختلطة للدفع لمستخدمين آخرين أو لأغراض شركتنا العامة أو غير ذلك، ومع
      ذلك، سنظل ملزمين بالإفراج عن الأموال أو ردها بناءً على توجيهاتك وفقًا بهذه
      الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      12. قواعد السلوك
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      12.1 باستخدام المنصة، يوافق المستخدمون على الالتزام بمدونة قواعد السلوك
      الخاصة بفرص، والتي تشكل شروطها جزءًا لا يتجزأ وملزمًا من هذه الاتفاقية
      ويتم قراءتها وتفسيرها معها باعتبارها جزءًا واحدًا.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      12.2 تنطبق قواعد سلوك فرصة على جميع الخدمات التي تقدمها فرصة، بما في ذلك،
      على سبيل المثال لا الحصر، خدمات تسوية المنازعات الرئيسية. لقد وافقت على
      أنك ستبذل قصارى جهدك لتحقيق اللعب النظيف وتقديم طلبات/عروض معقولة وعادلة
      بشأن النزاع الخاص بك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      12.3 قد يخسر المستخدم الذي يتبين أنه ينتهك قواعد السلوك أثناء عملية
      خدمة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتسوية النزاعات النزاع تلقائيًا لصالح الطرف الآخر المعني، بغض النظر
      عن أصل النزاع. قد يتعرض المستخدم الذي انتهك قواعد السلوك أيضًا إلى مزيد من
      الإجراءات التأديبية، بالإضافة إلى أي إجراءات وسبل انتصاف أخرى يمكننا
      اتخاذها ضده بموجب شروط هذه الاتفاقية. لمزيد من المعلومات، اقرأ قواعد
      السلوك. 13. الخرق
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      13.1 دون تقييد سبل الانتصاف الأخرى لدينا، إلى الحد الذي انتهكت فيه هذه
      الاتفاقية، يجب عليك أن تدفع لنا جميع الرسوم المستحقة لنا وأن تعوضنا عن
      جميع الخسائر والتكاليف (بما في ذلك أي وكل وقت موظفينا) والنفقات المعقولة
      (بما في ذلك الرسوم القانونية ) المتعلقة بالتحقيق في هذا الانتهاك وتحصيل
      هذه الرسوم.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      13.2 أنت تقر وتوافق على ما يلي: (1) الأضرار التي سنتكبدها نتيجة لخرقك لهذه
      الاتفاقية ستكون كبيرة ومن المحتمل أن تشمل (على سبيل المثال لا الحصر)
      الغرامات والنفقات الأخرى ذات الصلة المفروضة علينا من قبل معالجي الدفع
      والمستخدمين لدينا. وأن تلك الأضرار قد يكون من الصعب للغاية وغير عملي
      التحقق منها؛ (2) إذا قمت بخرق هذه الاتفاقية، فقد نفرض عليك غرامة تصل إلى
      3000 دولار أمريكي عن كل خرق و/أو قد نتخذ إجراءات قانونية ضدك لاسترداد
      الخسائر التي تزيد عن مبلغ الغرامة؛ (3) الغرامة التي تصل إلى 3000 دولار
      أمريكي هي تقدير مسبق معقول حاليًا أو الحد الأدنى لتقدير الأضرار التي
      نلحقها، مع الأخذ في الاعتبار جميع الظروف الحالية، بما في ذلك (على سبيل
      المثال لا الحصر) علاقة المبلغ بنطاق الضرر الذي يلحق بنا والذي يمكن أن يكون
      معقولًا أن يكون متوقعًا وتوقع أن إثبات الأضرار الفعلية قد يكون غير عملي أو
      صعب للغاية؛ و(4) يجوز لنا تحرير كامل (أو جزء من) مبلغ الغرامة من حسابك
      إلينا.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      13.3 إذا أغلقنا حسابك لسبب آخر غير نتيجة خرقك لهذه الاتفاقية، ما لم ينص
      على خلاف ذلك في هذه الاتفاقية، فسيحق لك الحصول على أي دفعة مستحقة منا لك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      13.4 في حالة قيامنا بإغلاق حسابك، فلن يكون لديك أي مطالبة على الإطلاق ضدنا
      فيما يتعلق بأي تعليق أو إنهاء لحسابك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      13.5 نحن نحتفظ بالحق، ولكن لا نتحمل الالتزام، في التحقيق في أي انتهاك
      لشروط هذه الاتفاقية. يجوز لنا التحقيق في الانتهاكات ويجوز لنا إزالة أو
      تعطيل الوصول إلى أو تعديل أي محتوى ينتهك هذه الاتفاقية. يجوز لنا الإبلاغ
      عن أي نشاط نشتبه في أنه ينتهك أي قانون أو لائحة إلى المسؤولين عن إنفاذ
      القانون أو الجهات التنظيمية أو الجهات الخارجية المناسبة الأخرى. قد تتضمن
      تقاريرنا الكشف عن معلومات المستخدم المناسبة. قد نتعاون أيضًا مع وكالات
      إنفاذ القانون المناسبة أو الهيئات التنظيمية أو غيرها من الأطراف الثالثة
      المناسبة للمساعدة في التحقيق والملاحقة القضائية للسلوك غير القانوني من
      خلال توفير معلومات الشبكة والأنظمة المتعلقة بالانتهاكات المزعومة لهذه
      الشروط. 14. إنهاء حسابات المستخدمين وآثار الإنهاء
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      14.1 قد يتم إنهاء حسابك من قبل فرصة في حالة:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. إذا فشلت في سداد المبلغ المطلوب بعد أن تلقيت إشعارًا كتابيًا مدته 48
      ساعة، من خلال المنصة أو غير ذلك؛ ب. لقد ارتكبت خرقًا ماديًا لأي تمثيل أو
      التزامات أو عهد أو ضمان أو شرط لهذه الاتفاقية ولم يتم علاج ذلك خلال 30
      يومًا بعد إشعار كتابي مقدم من&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ؛ ج. أصبحت مفلسًا أو قمت بإجراء أي تسوية أو ترتيبات مع دائنيك أو كونك
      شركة، أو كان لديك اقتراح لترتيب طوعي لتسوية الديون أو مخطط ترتيب أو لديك
      طلب إلى المحكمة لتعيين مسؤول، أو لديك أمر التصفية الصادر أو (باستثناء
      أغراض إعادة الإعمار) قرار التصفية الطوعية الصادر أو الحارس القضائي المعين
      أو الحيازة التي تم الاستيلاء عليها بواسطة أو نيابة عن أي دائن لأي ملكية
      موضوع رهن أو حارس قضائي معين بموجب سند دين؛ د. إذا كنت تنتهك/تنتهك حقوق
      الطرف الثالث بما في ذلك حقوق الملكية الفكرية؛ خام. أنت تنتهك شروط وأحكام
      هذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      14.2 قد يتم تعليق حسابك أو إلغاؤه أو إنهاؤه من قبل شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بعد تقديم إشعار كتابي لك مدته 30 يومًا. سيتم تعليق الحساب أو إنهائه
      بواسطة فرصة، وسيتم إنهاء هذه الاتفاقية تلقائيًا بعد انتهاء هذه الفترة، دون
      الحاجة إلى أمر من المحكمة أو إشعارات موثقة أو غير ذلك. يجب على&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;إزالة الروابط إلى حسابك وسحب وصولك إلى المستخدمين الآخرين بأثر فوري
      بشرط استكمال أي عطاءات مستمرة مقدمة أو خدمات يتم تنفيذها للمستخدم
      (المستخدمين) الآخرين. في مثل هذه الأحداث:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. تصبح جميع المبالغ المستحقة عليك لشركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;مستحقة فورًا عند استلام الفاتورة الإلكترونية النهائية، ويجب، حيثما
      أمكن، مقاصتها مقابل أي مبالغ مستحقة لك بموجب البند 13.2 (ب) أدناه. ب. سيتم
      تفريغ أي رصيد مستحق لك ضمن حسابك، وسيمثل أي وجميع المبالغ المستحقة لك من
      فرصة. عند سداد هذه المبالغ، لن تتحمل شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أي التزامات مالية أو التزامات إدارية أو غيرها من الالتزامات مهما
      كانت مستحقة لك، ويجب عليك إطلاق سراح شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بشكل كامل وإلى الأبد وجميع مسؤوليها ووكلائها ومديريها وموظفيها
      السابقين والحاليين والمستقبليين. المستثمرون، والمساهمون، والإداريون،
      والشركات التابعة، والأقسام، والشركات التابعة، والآباء، والشركات السابقة
      واللاحقة، والخلفاء والمتنازل لهم، من، ويوافقون على عدم رفع دعوى قضائية أو
      إقامة أو التسبب في إقامة أي إجراءات قانونية أو إدارية تتعلق بأي خسارة أو
      ضرر أو مطالبة أو واجب أو التزام أو سبب دعوى تتعلق بأي مسائل من أي نوع،
      سواء كانت معروفة حاليًا أو غير معروفة، مشتبه بها أو غير متوقعة، قد تكون
      لديك، والتي تنشأ عن أي إغفال أو أفعال أو حقائق فيما يتعلق بهذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      14.3 لن تكون فرصة مسؤولة عن أي خسارة أو أضرار (مباشرة أو غير مباشرة أو غير
      مهمة) تتكبدها أنت أو مستخدمون آخرون نتيجة لتعليق أو إنهاء
      حساباتك/حساباتهم. 15. التعويض
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      15.1 يقوم المستخدمون بتعويض وتعويض شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;وشركائها ومسؤوليها وموظفيها وممثليها ووكلائها من وضد جميع الخسائر
      والأضرار والمطالبات والدعاوى والإجراءات القانونية وأي شيء آخر ينشأ عن أو
      فيما يتعلق بأي مطالبة بما في ذلك على سبيل المثال لا الحصر المطالبة بأي
      انتهاك لأي حقوق ملكية فكرية أو أي حقوق أخرى لأي طرف ثالث أو للقانون، فيما
      يتعلق بالجودة والكمية وأي مطالبة فيما يتعلق بالخدمات، وخرق أي من الضمانات
      أو الإقرارات أو التعهدات المقدمة من قبل المستخدمين الآخرين أو فيما يتعلق
      بعدم الوفاء بأي من التزاماتهم أو الناشئة عن انتهاك المستخدمين الآخرين لأي
      قوانين ولوائح معمول بها بما في ذلك على سبيل المثال لا الحصر حقوق الملكية
      الفكرية وضريبة الدخل وضريبة الخدمة وضريبة القيمة المضافة والتشريعات الأخرى
      ذات الصلة. .
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      16. حدود المسؤولية
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      16.1 لن تتحمل شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;تحت أي ظرف من الظروف المسؤولية تجاه المستخدمين عن الخسارة و/أو
      الخسارة المتوقعة في الأرباح، أو عن أي أضرار مباشرة أو غير مباشرة أو عرضية
      أو تبعية أو خاصة أو نموذجية تنشأ عن موضوع هذه الاتفاقية و/أو استخدامهم لـ
      المنصة أو استلام أو أداء الخدمات أو غير ذلك، بغض النظر عن نوع المطالبة
      وحتى إذا تم إخطار المستخدمين بإمكانية حدوث مثل هذه الأضرار، على سبيل
      المثال لا الحصر، خسارة الإيرادات أو الأرباح المتوقعة أو خسارة الأعمال.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17. السرية
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.1 يوافق الطرفان ويقران بأنه في سياق استخدامهما للمنصة والتفاعل من
      خلالها/بسببها، يفهم كل طرف ("المتلقي") أنه يجوز لأي من الأطراف الأخرى
      ("الطرف المفصح") الكشف عن بعض المعلومات معلومات سرية. ويخضع هذا الكشف
      لأحكام هذا البند.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.2 يتعهد المتلقي بما يلي:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. يوافق المتلقي على الحفاظ على المعلومات السرية بسرية تامة، ولا يجوز له
      بدون موافقة كتابية مسبقة من الطرف المفصح، بشكل مباشر أو غير مباشر، الإعلان
      عن أي من المعلومات السرية أو الكشف عنها أو نشرها أو الكشف عنها لأي أشخاص
      أو كيانات بخلاف ممثليه الشخصيين والخلفاء والمتنازل لهم المسموح لهم الذين
      يحتاجون إلى معرفة تلك المعلومات ويلتزمون بالتزامات عدم الإفصاح في عقد
      الإيجار المقيدة مثل هذا البند. ب. يجب على المتلقي أن يبذل قصارى جهده لمنع
      استخدام المعلومات السرية أو الكشف عنها ويتعهد بالتأكد من أن المعلومات
      السرية محمية بتدابير أمنية ومحمية بشكل صحيح ضد السرقة والضرر والخسارة
      والوصول غير المصرح به. دون المساس بما سبق، يوافق المتلقي على ضمان درجة من
      العناية التي سيطبقها على معلوماته السرية وعلى أي حال لا تقل عن الدرجة
      المعقولة من الرعاية. ج. يجب على المتلقي أن يؤكد للطرف المفصح كتابيًا في أي
      وقت بناءً على طلب الطرف المفصح أنه امتثل لأحكام هذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      د. يوافق المتلقي على عدم تعديل أو فك أو تفكيك أو إجراء هندسة عكسية لأي جزء
      من المعلومات السرية أو جميعها. ه. يجب على المتلقي استخدام المعلومات السرية
      فقط لغرض المعاملة المقترحة بين أطراف هذه الاتفاقية ولا يجوز استخدامه
      لمصلحته أو لأي أغراض أخرى (بما في ذلك عدم استخدامها لتقديم المشورة لشركات
      أو كيانات أو أشخاص آخرين لأغراض تنافسية أو أي أغراض أخرى). الأغراض) حيث لا
      يجوز للمستلم استخدام أو استخدام المعلومات السرية دون موافقة كتابية صريحة
      من الطرف المفصح.&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      F
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . لا يجوز للمستلم إعادة إنتاج المعلومات السرية أو أي جزء منها، بأي شكل أو
      وسيط دون موافقة كتابية صريحة من الطرف المفصح.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.3 لا يجوز للمتلقي بدون موافقة كتابية صريحة مسبقة من الطرف المفصح الكشف
      عن أي معلومات سرية لأي شخص آخر غير الشركات التابعة له والمديرين وكبار
      المديرين التنفيذيين والمستشارين المحترفين الذين يُطلب منهم بالضرورة أثناء
      أداء واجباتهم تلقي و خذ بعين الاعتبار الأمر نفسه لأغراض المعاملة المقترحة
      ("المستلمون المعتمدون"). يجب على المتلقي التأكد من أن المستلمين المعتمدين
      الذين يتم الكشف عن المعلومات السرية لهم يجب أن يلتزموا بشروط هذه الاتفاقية
      ويلتزموا بها من جميع النواحي كما لو كانوا طرفًا فيها أو يتأكدون من أنهم
      ملتزمون باتفاقيات عدم الكشف على الأقل مقيدة مثل هذه الاتفاقية. في أي حال،
      يكون المتلقي مسؤولاً عن خرق أي من شروط الاتفاقية من قبل هؤلاء الأشخاص كما
      لو كان المتلقي هو الذي انتهك هذا الشرط.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.4 يجوز للمستلم الكشف عن المعلومات السرية إلى الحد الأدنى المطلوب بموجب
      (أ) أي أمر صادر عن أي محكمة ذات اختصاص قضائي أو أي هيئة قضائية أو حكومية
      أو تنظيمية مختصة؛ أو (ب) قواعد أي سلطة إدراج أو لائحة أو قانون معمول به أو
      بورصة ذات ولاية قضائية على المتلقي. في مثل هذه الحالة، يوافق المتلقي على
      أنه سيقدم للطرف المفصح إشعارًا سريعًا بهذا الطلب أو المطلب ويجب عليه الكشف
      عن هذه المعلومات السرية فقط إلى الحد الذي تتطلبه هذه القاعدة أو القانون أو
      اللوائح المعمول بها أو من قبل الحكومة أو السلطة التنظيمية ذات الصلة. أو
      المحكمة المختصة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.5 تظل جميع المعلومات السرية التي تم الكشف عنها ملكًا للطرف المفصح. يقر
      المتلقي (نيابة عنه ونيابة عن المستلمين المعتمدين) ويوافق على أن الكشف عن
      المعلومات السرية وتوفيرها بموجب هذه الاتفاقية من قبل الطرف المفصح لا يجوز
      تفسيره على أنه يمنح أي حقوق (سواء كانت صريحة أو ضمنية بموجب ترخيص أو غير
      ذلك) بشأن المسائل أو الاختراعات أو الاكتشافات التي تتعلق بها هذه المعلومات
      السرية أو أي حقوق نشر أو علامة تجارية أو حقوق أسرار تجارية) على المستلم أو
      المستلمين المعتمدين.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.6 يقر المتلقي (نيابة عنه ونيابة عن المستلمين المعتمدين) ويوافق على أنه
      لا يجوز للطرف المفصح ولا أي من مسؤوليه أو موظفيه أو وكلائه أو مستشاريه
      المحترفين تقديم أو تقديم أي تعهد أو ضمان، صريحًا أو ضمنيًا فيما يتعلق بدقة
      أو موثوقية أو اكتمال أي من المعلومات السرية أو أي معلومات أخرى مقدمة إلى
      المستلم أو تحمل أي مسؤولية عنها. ويكون المتلقي مسؤولاً عن إجراء تقييمه
      الخاص لهذه المعلومات السرية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.7 لا يكون الطرف المفصح ملزمًا بتحديث أو تصحيح أي عدم دقة في المعلومات
      السرية أو أي معلومات أخرى مقدمة إلى المتلقي أو يكون مسؤولاً تجاه المستلم
      أو المستلمين المعتمدين أو أي شخص آخر فيما يتعلق بأي من هذه المعلومات. يقر
      كل طرف ويضمن للطرف الآخر أنه شركة منظمة حسب الأصول وموجودة بشكل صحيح في
      الولاية القضائية لتأسيسها. يقر كل طرف بأنه يتمتع بالسلطة والسلطة المؤسسية
      الكاملة للدخول في هذه الاتفاقية والقيام بكل الأشياء اللازمة لتنفيذ هذه
      الاتفاقية. يضمن الطرف المفصح أن المعلومات السرية لم يتم تقديمها بشكل ينتهك
      أي اتفاقيات أخرى مع أطراف ثالثة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      17.8 عند تقديم الخدمات وتلقيها، أو أثناء التفاوض على شروط الخدمات، يمكن
      للمستخدمين، حسب خيارهم الوحيد، الدخول في اتفاقيات عدم إفشاء منفصلة فيما
      بينهم تحكم ذلك.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      18. العلاقة بين الأطراف
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      18.1 لن يتم تفسير أي شيء في هذه الاتفاقية على أنه ينشئ علاقة شراكة أو
      مشروع مشترك أو وكالة أو توظيف بين الطرفين. لن تكون فرصة مسؤولة عن أفعال أو
      إغفالات المستخدمين ولا يجوز للمستخدمين أن يمثلوا فرصة، ولا يكون لديهم أي
      سلطة أو سلطة للتحدث باسم أو تمثيل أو إلزام أو تحمل أي التزام نيابة عن
      فرصة. 19. القوة القاهرة
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      19.1 لن يكون أي من الطرفين مسؤولاً عن أي تأخير أو إخفاق في أداء التزاماته
      (بخلاف الالتزام بالسداد) بموجب هذه الاتفاقية بسبب ظروف غير متوقعة أو أي
      حدث خارج عن السيطرة المعقولة لذلك الطرف ودون خطأ منه أو الإهمال، على سبيل
      المثال لا الحصر، القضاء والقدر، الحرب، أعمال الشغب، الحظر، الإضرابات،
      الإغلاق، أعمال أي سلطة حكومية، التأخير في الحصول على التراخيص أو رفض
      الطلبات بموجب النظام الأساسي، فشل الاتصالات الهاتفية أو انقطاع التيار
      الكهربائي، الحرائق أو الفيضانات . 20. أحكام عامة
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.1 بناء الشروط. إذا اعتبر أي حكم من أحكام هذه الاتفاقية غير قابل للتنفيذ
      من قبل محكمة ذات اختصاص قضائي، فسيتم قطع هذا الحكم ولن يؤثر على صحة
      الأحكام المتبقية أو قابليتها للتنفيذ.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.2 القانون الحاكم. تخضع هذه الاتفاقية وتفسر وفقًا لقوانين مصر، كما تنطبق
      على ولاية القاهرة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.3 الاتفاقية الكاملة. تشكل هذه الاتفاقية الاتفاقية الكاملة وتحدد التفاهم
      والاتفاق الكامل بين الأطراف فيما يتعلق بموضوع هذه الاتفاقية وتحل محل جميع
      المناقشات والتفاهمات السابقة فيما يتعلق بموضوع هذه الاتفاقية، سواء كانت
      مكتوبة أو شفهية. تصبح هذه الاتفاقية ملزمة عند إقرار المستخدمين بأنهم قد
      قرأوا شروطها وأحكامها ووافقوا عليها ويتعهدون بالالتزام بها. في حالة عدم
      وجود إقرار صريح، فإن استخدام المستخدمين للمنصة يشكل قبولهم وتعهدهم
      بالالتزام بشروط وأحكام هذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.4 حل النزاعات. دون الإخلال بأحكام البند 10، إذا نشأ نزاع من أي نوع بين
      الطرفين فيما يتعلق بهذه الاتفاقية أو ناشئ عنها (والتي يجب أن تشمل أي نزاع
      يتعلق بأي إشعار أو قرار أو قرار أو تعليمات أو موافقة) أو الموافقة (أو أي
      عدم وجودها) أو الشهادة أو أي ممارسة للسلطة أو السلطة التقديرية (أو الفشل
      في ممارسة أي سلطة أو سلطة تقديرية من هذا القبيل)) من قبل الأطراف، حسب
      الحالة، سواء أثناء أو قبل أو بعد إنهاء الاتفاقية في هذه الاتفاقية
      ("النزاع")، يجوز لأي طرف إخطار الطرف الآخر بوجود هذا النزاع، ويجب على
      الطرفين بذل قصارى جهدهما لتسوية هذا النزاع وديًا. ولتحقيق هذه الغاية، إذا
      لم يتمكن الطرفان من حل النزاع وديًا خلال فترة ثلاثين (30) يومًا من التاريخ
      الذي يقوم فيه أي من الطرفين بإخطار الطرف الآخر بوجود النزاع، فبعد مرور
      ثلاثين (30) يومًا يجوز إحالة النزاع إلى التحكيم وفقاً للشروط التالية:
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      أ. إذا نشأ نزاع بينك وبين فرصة، فإن هدفنا هو معالجة مخاوفك، وإذا لم نتمكن
      من القيام بذلك بما يرضيك، فلتزويدك بوسيلة لحل النزاع بسرعة. نحن نشجعك بشدة
      على الاتصال بنا مباشرةً أولاً للحصول على حل عن طريق مراسلتنا عبر البريد
      الإلكتروني على&nbsp;
    </span>
    <span style={{ color: "black" }}>
      <a href="mailto:foras.managment@gmail.com" target="_blank">
        <span
          dir="LTR"
          style={{
            fontSize: 13,
            fontFamily: '"Helvetica Neue"',
            color: "#1155CC"
          }}
        >
          foras.managment@gmail.com
        </span>
      </a>
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      . إذا لم يتم حل أي نزاع عن طريق حل ودي، وفقًا لما ورد أعلاه، يجب على أي
      طرف إحالة هذا النزاع إلى تسويته نهائيًا. بموجب القواعد. يجب أن يكون هناك
      ثلاثة (3) محكمين يتم تعيينهم وفقًا للقواعد. يكون المقر القضائي للتحكيم هو
      مركز القاهرة المالي الدولي، القاهرة، مصر، ويجب استخدام اللغة الإنجليزية
      طوال إجراءات التحكيم. يكون قرار هيئة التحكيم نهائيًا وغير قابل للاستئناف،
      ويتم تنفيذه بنفس الطريقة كما لو كان حكمًا قضائيًا نهائيًا. يحدد قرار
      التحكيم بشكل عادل الطريقة التي يدفع بها الطرفان جميع التكاليف والمصروفات
      (بما في ذلك أتعاب وتكاليف المحاماة والمحكمين المعقولة والمتكبدة بشكل صحيح)
      للتحكيم. يوافق كل طرف على عدم بدء أي إجراءات أمام أي محكمة فيما يتعلق
      بالنزاع إلا لأغراض تنفيذ حكم التحكيم النهائي. يجوز إصدار الحكم على قرار
      التحكيم النهائي في أي محكمة لها ولاية قضائية أو ولاية قضائية على طرف أو
      أصوله. يجب حل جميع المطالبات التي ترفعها ضد فرصة وفقًا لشروط هذه
      الاتفاقية. تعتبر جميع المطالبات المقدمة أو التي تتعارض مع هذه الاتفاقية
      مقدمة بشكل غير صحيح وانتهاكًا لهذه الاتفاقية. في حالة تقديم مطالبة تتعارض
      مع شروط هذه الاتفاقية، يجوز لشركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;استرداد الرسوم والتكاليف القانونية (بما في ذلك المحامين الداخليين
      والمساعدين القانونيين). إذا تم بدء أي إجراء من جانبك أو ضدك بموجب أي حكم
      من أحكام أي قانون إفلاس أو إعسار، يحق لشركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;استرداد جميع التكاليف أو النفقات المعقولة (بما في ذلك الرسوم
      والنفقات القانونية المعقولة) المتكبدة فيما يتعلق بتنفيذ هذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ب. في حالة نشوء نزاع بين العميل ومقدم الخدمة فيما يتعلق بأي من الخدمات،
      فسوف تحاول أولاً حل أي خلافات لديك فيما يتعلق بهذه الخدمات. سيتم التعامل
      مع أي نزاع لا يتعلق بدفعة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;(والتي يجب التعامل معها وفقًا لسياسة نزاعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ) التي تنشأ بينك وبين مستخدم آخر وفقًا لهذا البند. سوف تتمتع شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بالحقوق والصلاحيات الكاملة لاتخاذ قرار بشأن جميع هذه النزاعات. عند
      استلام النزاع، يحق لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;أن تطلب من مزود الخدمة والعميل تقديم المستندات الداعمة لمطالبتهم أو
      موقفهم فيما يتعلق بالنزاع. أنت توافق على أن لدى&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;السلطة التقديرية المطلقة لقبول أو رفض أي مستند مقدم. أنت تقر أيضًا
      بأن&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;ليست مؤسسة قضائية أو بديلة لتسوية المنازعات وأننا لن نتخذ القرارات
      إلا كشخص عادي عاقل. بالإضافة إلى ذلك، نحن لا نضمن أن المستندات المقدمة من
      أطراف النزاع ستكون صحيحة أو كاملة أو صحيحة، وأنت توافق على تعويض و(إلى
      الحد الأقصى الذي يسمح به القانون) حماية شركة&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;وأي من الشركات التابعة لنا من أي أضرار. أو المسؤولية التي قد تتحملها
      نتيجة لأي وثائق أو مواد يتبين فيما بعد أنها كاذبة أو مضللة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      ج. فيما يتعلق بالنزاعات مع أي مستخدمين آخرين للمنصة، فإنك توافق بموجب هذا
      على تعويض فرصة عن أي وجميع المطالبات والمطالب والأضرار، الفعلية والتبعية،
      من كل نوع وطبيعة، معروفة وغير معروفة، المرتبطة بهذا النزاع. أو قراراتنا أو
      استخدام خدمات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;لتسوية النزاعات لمدفوعات&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      Milestone
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;و/أو للنزاعات الأخرى. د. إذا كان لديك نزاع مع واحد أو أكثر من
      المستخدمين، فإنك تعفينا (ومسؤولينا ومديرينا ووكلائنا وشركاتنا التابعة
      والمشاريع المشتركة والموظفين) من المطالبات والمطالبات والأضرار (الفعلية
      والتبعية) من كل نوع وطبيعة، معروفة وغير معروفة. الناشئة عن مثل هذه
      النزاعات أو المرتبطة بها بأي شكل من الأشكال.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.5 التنازل عن الخرق. لا يجوز أن يعمل تنازل أي طرف عن خرق أي حكم من أحكام
      هذه الاتفاقية من قبل الطرف الآخر أو يتم تفسيره على أنه تنازل عن أي خرق آخر
      أو لاحق من قبل الطرف المخالف.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.6 التعديل. قد تقوم&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;بإجراء تغييرات على هذه الاتفاقية. ما لم تذكر فرصة خلاف ذلك، ستصبح
      التغييرات الجوهرية على الاتفاقية سارية بعد 30 يومًا من نشرها، إلا إذا كانت
      التغييرات تنطبق على الوظائف الجديدة وفي هذه الحالة ستكون سارية على الفور.
      ما لم يُنص على خلاف ذلك صراحةً، فإن أي ميزات جديدة تعمل على تعزيزها أو
      تحسينها أو تغييرها تخضع لهذه الاتفاقية.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.7 المهمة. لا يمكن التنازل عن هذه الاتفاقية أو أي جزء منها أو نقلها أو
      ترخيصها من الباطن أو التعاقد عليها من الباطن أو نقلها من قبل المستخدمين،
      سواء بموجب قانون أو غير ذلك، دون موافقة كتابية صريحة ومسبقة من&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;موقعة من ممثل معتمد لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;مثل هذا الحزب. يحق لـ&nbsp;
    </span>
    <span
      dir="LTR"
      style={{ fontSize: 13, fontFamily: '"Helvetica Neue"', color: "#222222" }}
    >
      FORAS
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;رفض هذه الموافقة.
    </span>
  </p>
  <p
    dir="RTL"
    style={{
      margin: "0in",
      fontSize: 16,
      fontFamily: '"Calibri",sans-serif',
      textAlign: "right",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      20.8 اتصل بـ&nbsp;
    </span>
    <span style={{ color: "black" }}>
      <a href="mailto:Foras.managment@gmail.com" target="_blank">
        <span
          dir="LTR"
          style={{
            fontSize: 13,
            fontFamily: '"Helvetica Neue"',
            color: "#1155CC"
          }}
        >
          Foras.managment@gmail.com
        </span>
      </a>
    </span>
    <span
      style={{
        fontSize: 13,
        fontFamily: '"Arial",sans-serif',
        color: "#222222"
      }}
    >
      &nbsp;للحصول على أي معلومات إضافية.
    </span>
  </p>
  <p
    style={{ margin: "0in", fontSize: 16, fontFamily: '"Calibri",sans-serif' }}
  >
    &nbsp;
  </p>
</div>

 )
};

export default TermsConditions;
