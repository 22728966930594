import React, { useState, useEffect, useRef } from 'react'
import { Rating } from 'react-simple-star-rating'
import "./stylesheets/ApplyForas.css"
import ForasTag from './components/ForasTag'
import PdfLogo from './stylesheets/imgs/pdflogo.svg'
import PngLogo from './stylesheets/imgs/pnglogo.png'
import FileIcon from './stylesheets/imgs/file.png'
import axios from 'axios'
import { maxUserFileSize, validApplicationFileTypes, validResumeTypes } from '../config'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { calculateTimeAgo } from '../utils/JobUtils'
import Loading from './components/Loading'
import ProfileImage from './components/ProfileImage'
import AsyncSelect from 'react-select/async'
import { useUser } from '../hooks/useUser'
import { Modal } from 'react-bootstrap'
import DownloadLink from './components/DownloadLink'
import { FaUpload } from 'react-icons/fa';
import Status from './components/Status'
import { formatNewLine } from '../utils/TextUtils.js'
import CustomModal from './components/CustomModal.js'


export const SimilarTabs = ({ id, title, description, payment, owner, loading }) => {
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let smallSize = windowWidth > 1024 ? 100 : 50;

    return (
        <div className='tab' id='centerdiv' style={{ alignItems: 'normal' }}>
            {loading ? <Loading /> :
                !id ? <></> :
                    <>
                        <div className='similarjobTitlte'>
                            <img id='similarjobTitlteimg' src={owner?.imageURL || "https://via.placeholder.com/30"} />
                            <div id='SimilarForasTop'>
                                <h4>{title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h4>
                                <h5>{owner?.firstName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} {owner?.lastName}</h5>
                            </div>
                        </div>
                        <div className='similarjobDesc'>
                            {/* TODO This is where the dangerously html was placed */}
                            <h4 id='ThirdDesc'> {formatNewLine(description.substring(0, smallSize), 3)}...</h4>
                        </div>
                        <div className='endingSimilarJob'>
                            <h4 id='jobPayment'>{payment}</h4>
                            <button id='SimilarJobApply' onClick={() => {
                                navigate(`/ApplyForas/${id}`)
                                // refresh
                                navigate(0)
                            }}>Apply {windowWidth > 768 ? "Now" : ""}</button>
                        </div>
                    </>}
        </div>
    )
};


export default function ApplyForas() {
    //* Job and page states
    const [job, setJob] = useState(undefined)
    const [owner, setOwner] = useState(undefined)
    const [similar, setSimilar] = useState([])
    const [viewerStatus, setViewerStatus] = useState()

    //* Application states
    // General
    const [appId, setAppId] = useState("")
    const [createStatus, setCreateStatus] = useState()
    const [isUpdating, setIsUpdating] = useState(false)
    // Files
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const [filesToUpload, setFilesToUpload] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([])
    const cvInputRef = useRef()
    // User data inputs
    const [bid, setBid] = useState("")
    const [userData, setUserData] = useState({})
    const fetchedUserData = useRef()

    //* Invitation states
    const usernameInputRef = useRef()
    const [invitationModalShown, setInvitationModalShown] = useState(false)
    const [invitations, setInvitations] = useState()

    //* Other hooks
    const curUser = useUser()
    const navigate = useNavigate()
    const location = useLocation()
    const { id: jobId } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [showApplyModal, setShowApplyModal] = useState(false);

    // SIMILAR JOBS
    function genSimilarTabs() {
        const res = []
        for (let i = 0; i < 3; i++) {
            let sim = similar[i]
            let payment = sim?.isBid ? "Up for bid" : `${sim?.price}LE`
            if (sim) {
                res.push(
                    <SimilarTabs
                        key={sim?._id}
                        id={sim?._id}
                        title={sim?.title}
                        description={sim?.description}
                        payment={payment}
                        owner={sim?.owner}
                        loading={!owner}
                    />
                )
            }
        }
        return res;

    }

    // FILE HANDLERS
    /** @param {import('react').DragEvent<HTMLDivElement>} event */
    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setFilesToUpload(file);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        // Add styling or effects to indicate the drop area
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        // Remove styling or effects from the drop area
    };

    const handleFileChange = (event) => {
        if (!event.target.files[0]) return

        setFilesToUpload(event.target.files[0]);
    };

    /** @param {File} file */
    function handleFileClick(file) {
        const fr = new FileReader()
        fr.onload = () => {
            const a = document.createElement('a')
            a.href = fr.result
            a.download = file.name
            a.click()
            a.remove()
        }
        fr.readAsDataURL(file)
    }

    function handleFileRemove(index) {
        const file = uploadedFiles[index]

        setFilesToDelete(prev => {
            if (!prev) prev = []

            const updated = [...prev]
            updated.push(file._id)
            return updated
        })

        setUploadedFiles(prev => {
            if (!prev) prev = []

            const updated = [...prev]
            updated.splice(index, 1)
            return updated
        })

    }

    // INVITATIONS
    async function viewInvitations() {
        setInvitationModalShown(true)

        if (!invitations) {
            axios.get(`/applications/invitations/${curUser.id}/${jobId}`)
                .then(result => {
                    setInvitations(result.data)
                })
        }
    }

    async function handleAcceptInvitation(index) {
        axios.put('applications/invitation/accept', { user: curUser.id, app: invitations[index]?._id })
            .then(result => {
                setInvitations(prev => {
                    const updated = [...prev]
                    if (updated[index]) {
                        const cur = updated[index].users.find(e => e.user._id === curUser.id)
                        if (cur) cur.accepted = true
                    }
                    return updated
                })
            })
    }

    async function handleRejectOwnInvitation() {
        setCreateStatus('cancelling')
        axios.delete('applications/invitation/reject', { data: { user: curUser.id, app: appId } })
            .then(result => {
                setIsUpdating(false)
                setAppId(null)
            })
            .finally(() => {
                setCreateStatus(null)
            })
    }

    async function handleRejectInvitation(index) {
        axios.delete('applications/invitation/reject', { data: { user: curUser.id, app: invitations[index]?._id } })
            .then(result => {
                setInvitations(prev => {
                    const updated = [...prev]
                    updated.splice(index, 1)
                    return updated
                })
            })
    }

    // MISCELLANEOUS
    async function handleUsernameFilter(text) {
        // Handling user typing, don't want to fire a request with every keypress, so I set a timeout before search is fired
        try {
            usernameInputRef.current = text
            const promise = new Promise((resolve, reject) => setTimeout(() => {
                if (usernameInputRef.current === text) {
                    resolve()
                    return;
                }
                reject();
            }, 400))

            await promise;
        } catch (error) {
            return;
        }

        // Sending search request
        return axios.get(`/users/findByUsername/${text}`, { params: { user: curUser.id } })
            .then(result => result.data.map(e => ({ value: e._id, label: e.username })))
            .catch(error => {
                console.error(error);
            })
    }

    async function handleApply() {
        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }

        if (job.isBid && !bid?.trim()) {
            alert("Bid cannot be empty")
            return;
        }

        if (!(userData.name?.trim().length > 0) || !(userData.email?.trim().length > 0) || !(userData.phone?.trim().length > 0)) {
            alert("Please fill in all of the fields")
            return;
        }

        if (!filesToUpload) {
            alert("Please upload your CV")
            return;
        }

        if (filesToUpload) {
            if (filesToUpload.size > maxUserFileSize) {
                alert(`File too large. Max size is ${maxUserFileSize / (1024 * 1024)}MB`);
                return;
            }

            // Validating file type
            if (!validApplicationFileTypes.split(',').includes(filesToUpload.type)) {
                alert("Invalid file type. Allowed types are: " + validApplicationFileTypes);
                return;
            }
        }

        setCreateStatus(isUpdating ? 'updating' : 'creating')
        try {
            // Creating application
            const application = await axios.put('/applications/create', {
                job: jobId,
                users: [curUser.id, ...(selectedUsers.map(e => e.value))],
                user: curUser.id,
                bid,
                contact: userData
            }).then(result => {
                setIsUpdating(true)
                return result
            })

            const proms = []
            if (filesToDelete?.length > 0) {
                setCreateStatus("uploading")

                proms.push(
                    axios.delete('/applications/files/deleteMany', {
                        data: {
                            app: appId,
                            user: curUser.id,
                            files: filesToDelete
                        }
                    })
                )
            }

            if (filesToUpload) {
                setCreateStatus("uploading")
                // Creating form data
                const formData = new FormData()
                formData.append('user', curUser.id)
                formData.append('file', filesToUpload)
                formData.append('app', application.data._id)
                proms.push(
                    axios.post('/applications/files/upload',
                        formData,
                        { onUploadProgress: e => console.log(e) }
                    ).then(result => {
                        setUploadedFiles(prev => {
                            const updated = [...prev]
                            updated.push(filesToUpload)
                            setFilesToUpload(null)
                            return updated
                        })
                    })
                )
            }

            await Promise.all(proms)
            setCreateStatus(null)
            closeApplyModal()
        } catch (error) {
            setCreateStatus(null)
        }
    }

    async function fetchData() {
        axios.get(`/users/view/${curUser.id}`, { params: { justName: true, requester: curUser.id } }).then(result => {
            fetchedUserData.current = result.data
            setUserData({ name: result.data.fullName, email: result.data.email })
        }).catch(() => { })
        var result;
        result = await axios.get(`/jobs/view/${jobId}`, {
            params: {
                getOwner: true,
                getSimilar: true,
                getUserStatus: true,
                getApp: true,
                getAppFiles: true,
                user: curUser.id
            }
        })
        try {
            setJob(result.data.job)
            setOwner(result.data.owner)
            setSimilar(result.data.similar)
            setViewerStatus(result.data.viewerStatus)

            // If this user has created an application for this job
            if (result.data.app) {
                setIsUpdating(true)
                setAppId(result.data.app._id)
                setBid(result.data.app.bid?.toString() || '')
                setSelectedUsers(
                    result.data.app.users?.filter(e => e.user._id !== curUser.id)
                        .map(e => ({ value: e.user._id, label: e.user.username })) || [])

                if (result.data.app.files) {
                    setUploadedFiles([...result.data.app.files])
                }
            } else {
                setIsUpdating(false)
            }
        } catch (error) {
            console.error(error)
            alert(error.message)
        }
    }

    function updateUserData(field, value) {
        setUserData(prev => ({ ...prev, [field]: value }))
    }

    function closeApplyModal() {
        setShowApplyModal(false)
        setFilesToUpload(null)
        setUserData({ name: fetchedUserData.current.fullName, email: fetchedUserData.current.email })
    }
    useEffect(() => {
        fetchData()
    }, [curUser.loggedIn, jobId])

    useEffect(() => {
        if (!job) return;

        if (['progress', 'review', 'complete'].includes(job.status)) {
            navigate(`/ActiveForsa/${jobId}`)
        }
    }, [job])

    return (
        <div className='ApplyForasPage'>
            <CustomModal
                show={showApplyModal}
                close={() => closeApplyModal()}
                text={{ id: 'modal-header', content: 'Confirm Your Application' }}
                inputs={[
                    { id: 'name-input', type: 'text', placeholder: 'Enter text', value: userData.name, onChange: (e) => updateUserData('name', e.target.value), label: 'Name*' },
                    { id: 'email-input', type: 'email', placeholder: 'Enter email', value: userData.email, onChange: (e) => updateUserData('email', e.target.value), label: 'Email*' },
                    { id: 'phone-input', type: 'email', placeholder: 'Enter phone number', value: userData.phone, onChange: (e) => updateUserData('phone', e.target.value), label: 'Phone Number*' },
                ]}
                buttons={[
                    { id: 'cancel-button', text: 'Cancel', onClick: () => closeApplyModal(), ...createStatus && { style: { display: 'none' } } },
                    { id: 'apply-button', text: 'Apply', onClick: () => handleApply(), ...createStatus && { style: { display: 'none' } } },
                ]}
            >
                <div className='custommodalRow'>
                    <p>CV*</p>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                        <a style={{ color: 'black' }} onClick={() => handleFileClick(filesToUpload)}>{filesToUpload?.name.slice(0, 25)}{filesToUpload?.name.length > 25 ? '...' : ''}</a>
                        <a onClick={(e) => { e.preventDefault(); cvInputRef.current?.click(); }}>{!!filesToUpload ? 'Change' : 'Attach'}</a>
                        <input ref={cvInputRef} type='file' accept={validResumeTypes} style={{ display: 'none' }} onChange={handleFileChange} />
                    </div>
                </div>
                {createStatus && <Loading size={28} containerHeight='28px' />}
                {/* <div className='custommodalRow'>
                    <p>Would you like to attach any other documents?</p>
                    <a>Attach</a>
                </div> */}
            </CustomModal>
            <div className='ForsaDetails'>
                <div className='leftDetails'>
                    {
                        owner ?
                            <>
                                {/* Rendered status in a different div */}
                                <div className='statusWrapper'>
                                    <Status getStatus={true} isUpdated={isUpdating} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 0 }}>
                                    <h2 id='jobtitleNew' style={{ margin: 0, fontStyle: 'italic', marginBottom: '2.5%', fontWeight: 500 }}>{job.title.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h2>
                                    {/* {(!curUser.loggedIn || curUser.isRole('employee')) && <h3 disabled={createStatus} onClick={handleApply}>Applied!</h3>} */}
                                    {(!curUser.loggedIn || curUser.isRole('employee')) && <button id='ApplyNowButton' disabled={createStatus} onClick={() => setShowApplyModal(true)}>{isUpdating ? 'Update' : 'Apply Now'}</button>}
                                    {isUpdating && <button id='ApplyNowButton' style={{ backgroundColor: 'rgba(255, 0, 0, 0.33)' }} onClick={handleRejectOwnInvitation}>Cancel</button>}
                                </div>
                                <div className='leftProfile'>
                                    <ProfileImage id={owner._id} imageURL={owner.imageURL} />
                                    <div className='ProfileDetails' style={{ width: windowWidth > 1024 ? '45%' : '70%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Link to={`/Profile?userID=${owner._id}`}><h4 style={{ margin: 0, fontWeight: 600, fontSize: '2svh' }} >{owner.fullName}</h4></Link>
                                            <h4 style={{ margin: 0, fontWeight: 400, fontSize: '2svh', color: 'black', userSelect: 'none', fontStyle: 'italic' }} >{job.city}, Egypt</h4>
                                            <h4 style={{ margin: 0, fontWeight: 400, fontSize: '2svh', color: 'black', userSelect: 'none', fontStyle: 'italic' }} >Posted {calculateTimeAgo(job.createdAt)}</h4>
                                        </div>
                                        {/* <img src = {RatingGround}/> */}
                                        {/* <Rating initialValue={owner.rating?.average} readonly={true} allowFraction={true} size={'20'} /> */}
                                        {job ?
                                            <div id='displayApplyTag'>
                                                {
                                                    job.tags?.slice(0, 3).map((tag, i) =>
                                                        <div key={i} id="ApplyForasTag">
                                                            <h3>{tag}</h3>
                                                        </div>
                                                    )
                                                }
                                                <div id="ApplyForasTag">
                                                    {
                                                        job.durationDays / 30 < 12 ?
                                                            <h3>{(Math.round(job.durationDays / 30))} months</h3>
                                                            :
                                                            <h3>{(
                                                                Math.round(job.durationDays / 30) / 12
                                                            )} year</h3>
                                                    }
                                                </div>
                                            </div>

                                            :
                                            <>
                                                <Loading />
                                            </>
                                        }
                                    </div>
                                    {job.isBid && <input type='number' value={bid} onChange={e => setBid(e.target.value)} placeholder='Enter bid...' />}
                                </div>
                                {/* <div className='moreDetails'>
                                    <h4 style={{padding:0, margin:0}}>{owner.jobCount || 0} For{owner.jobCount === 1 ? "sa" : "as"} Created</h4>
                                </div> */}
                                {/* <Link to={`/Profile?userID=${owner._id}`}><h4>Other Foras by {owner.fullName?.split(' ')[0]}</h4></Link> */}
                            </>
                            : <Loading />
                    }
                </div>
                <div className='rightDetails'>
                    {job ?
                        <>

                            <div className='PropertyTags'>
                                {/* <div className='SecondTag'>
                                    <div className='duration'>
                                        <h4>Posted {calculateTimeAgo(job.createdAt)}</h4>
                                    </div>
                                </div> */}

                                <div className='ThirdTag'>
                                    <div style={{ marginBottom: '2.5%' }}>
                                        <h4 id='AboutThisRole'>Job Type: <span>
                                            {job.type === 'fullTime' ? 'Full Time' : job.type === 'partTime' ? 'Part Time' : job.type === 'remote' ? 'Remote' : job.type}
                                        </span>
                                        </h4>
                                        <h4 id='AboutThisRole'>Department: <span>{job.categoryID?.name}</span></h4>
                                        <h4 id='AboutThisRole'>Start Date: <span>{new Date(job.startDate).toLocaleDateString('en-eg', { day: '2-digit', month: 'long', year: 'numeric' })}</span></h4>
                                        <h4 id='AboutThisRole'>Deadline: <span>{new Date(job.endDate).toLocaleDateString('en-eg', { day: '2-digit', month: 'long', year: 'numeric' })}</span></h4>
                                        <h4 id='AboutThisRole'>Compensation:<span> {job.isBid ? "Up for bid" : `${job.price} EGP`}</span></h4>
                                        <h4 id='AboutThisRole' style={{ marginBottom: 0, fontSize: '2svh' }}>About this role</h4>
                                        {/* TODO This is where the dangerously html was placed */}
                                        <h4 id='ThirdDesc'> {formatNewLine(job.description)}</h4>
                                    </div>
                                    {/* <div style={{ marginBottom: '5%' }}>
                                        <h4 id='AboutThisRole'>Qualifications</h4>
                                        <h4 id='ThirdDesc'>Placeholder text until the placeholder text no longer becomes a placeholder text</h4>
                                    </div> */}
                                </div>
                                <div style={{}}>
                                    {uploadedFiles.map((e, i) => {
                                        return <div key={e._id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                                            <DownloadLink
                                                downloadName={e.name}
                                                requestBody={{ user: curUser.id, file: e._id, app: appId }}
                                                url={'applications/files/download'}
                                            >
                                                <h4>{e.name}</h4>
                                                {/* TODO: Display download progress here */}
                                            </DownloadLink>
                                            <button style={{}} onClick={() => handleFileRemove(i)}>Remove</button>
                                        </div>
                                    })}
                                </div>
                                {/* <div
                                    className='dropArea'
                                    onDrop={e => handleFileDrop(e)}
                                    onDragOver={(event) => event.preventDefault()}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                >
                                    {filesToUpload ? (
                                        <div className='fileInfo' onClick={() => handleFileClick(filesToUpload)}>
                                            {filesToUpload.name.endsWith('.pdf') ? (
                                                <img src={PdfLogo} alt='PDF Logo' style={{ width: 20 }} />
                                            ) : filesToUpload.name.endsWith('.png') ? (
                                                <img src={PngLogo} alt='PNG Logo' style={{ width: 20 }} />
                                            ) : (
                                                <img src={FileIcon} alt='File Icon' style={{ width: 20 }} />
                                            )}
                                            <p style={{ fontSize: 10 }}>{filesToUpload.name}</p>
                                        </div>
                                    ) : (
                                        <p>Drag and drop a file here or click to upload.</p>
                                    )}
                                </div> */}

                                {/* <input id='choosefile' type='file' onChange={handleFileChange} /> */}
                                {/* <div className="file-input-container">
                                    <input id="choosefile" type="file" onChange={handleFileChange} />
                                    <FaUpload className="file-input-icon" />
                                </div> */}
                                {/* <p>Invite other freelancers to join your team!</p>
                                <AsyncSelect
                                    placeholder="Username..."
                                    defaultValue={""}
                                    loadOptions={handleUsernameFilter}
                                    isMulti
                                    value={selectedUsers}
                                    onChange={setSelectedUsers}
                                /> */}
                                {/* <div className='FourthTag'>
                                    <div className='submittedpeople'>
                                        <h4>{job.applicantCount || 0} Applicant{job.applicantCount === 1 ? "" : "s"}</h4>
                                    </div>
                                    {viewerStatus?.startsWith("applied") && <button id='ViewInvitationsButton' onClick={viewInvitations}>View Invitations</button>}
                                </div> */}

                            </div>
                            <Modal
                                id='popUpModal'
                                show={invitationModalShown}
                                style={{}}
                            >
                                <div id='userRow' style={{ justifyContent: 'center' }}>
                                    <h4 style={{}}>Invitations</h4>
                                </div>
                                {!invitations ?
                                    <Loading /> :
                                    invitations.map((e, index) => {
                                        let curHasAccepted = false
                                        return <div key={e._id} id='userRow'>
                                            {e?.users?.map(u => {
                                                if (u.user._id === curUser.id && u.accepted) {
                                                    curHasAccepted = true
                                                }
                                                return <Link id='LinkuserRow' key={u.user._id} to={`/Profile/?userID=${u.user._id}`} target="_blank">
                                                    <h4 style={{ color: u.accepted ? 'black' : '#34AED4', marginBlock: 0 }}>
                                                        {u.user._id === curUser.id ? "" : "Join @"}{u.user.username} {u.user._id === curUser.id ? "(You)" : ""}
                                                    </h4>
                                                </Link>
                                            })}
                                            <button id='buttonModal' style={{ backgroundColor: 'rgba(255, 0, 0, 0.33)' }} onClick={() => handleRejectInvitation(index)}>{'Reject'}</button>
                                            {!curHasAccepted && <button id='buttonModal' onClick={() => handleAcceptInvitation(index)}>Accept</button>}
                                        </div>
                                    })
                                }
                                <button id='buttonModal' style={{ marginTop: '2.5%' }} onClick={() => setInvitationModalShown(false)}>Close</button>
                            </Modal>
                            {/* <Modal
                                show={createStatus && createStatus !== 'success'}
                                style={{ width: '50%', height: '50%', backgroundColor: 'white', position: 'fixed', top: '25%', left: '25%' }}
                            >
                                <Modal.Body >
                                    <Loading />
                                    <p>{createStatus}</p>
                                </Modal.Body>
                            </Modal> */}
                        </>
                        : <Loading />}
                </div>
            </div>

            <div className='SimilarForasSection'>
                <h3 style={{ textAlign: 'left', padding: '5%', fontStyle: 'italic' }}>Similar Foras</h3>
                <div className='SimilarForas'>
                    {genSimilarTabs()}
                    <Link id='LoadMoreButton' to='../foras' target='_blank'>Explore</Link>
                </div>
            </div>
        </div>
    )
}

