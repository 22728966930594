import axios from "axios"
import Cookies from "js-cookie"

export async function login(event, setLoading, navigate, location) {
    event.preventDefault()
    try {
        setLoading(true)

        const response = await axios.post(`/users/signIn`, {
            user: event.target.user.value,
            password: event.target.pass.value
        }, { withCredentials: true })

        setLoading(false)
        if (location.state?.from && !location.state.from.includes('Login')) {
            navigate(location.state.from, { replace: true })
            return
        }
        navigate('/Home', { replace: true })

    } catch (error) {
        Cookies.remove('token_payload')
        setLoading(false)
        throw error // Throw the error so it can be caught in the Login component
    }
}

export async function logout(event, navigate) {
    event.preventDefault()

    try {
        await axios.post('/users/signOut')
        navigate('/')
    } catch (error) {
        console.log(error)
    }
}

export function deleteToken() {
    Cookies.remove('token_payload')
}
