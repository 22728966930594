import axios from "axios";
import PasswordStrengthIndicator from "./components/PasswordStrengthIndicator";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { checkPassStrength } from "../utils/AuthUtils";
import { passwordStrengthThreshold } from "../config";
import Loading from "./components/Loading";
import '../stylesheets/forgetpassword.css'
import ForasLogo from '../imgs/small_logo.png';

export default function ResetPassword() {

  const searchParams = useSearchParams()[0]
  const navigate = useNavigate()
  const [strength, setStrength] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  function handlePasswordSubmit(e) {
    e.preventDefault()

    if (strength < passwordStrengthThreshold) {
      setError("Minimum password strength is \"Medium\"")
      return;
    }

    setLoading(true)
    axios.post('/users/resetPass', {
      token: searchParams.get('token'),
      password: e.target.pass.value
    })
      .then(res => navigate('/Login'))
      .catch(error => {
        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="forgetPassword">
        <div className="forgetWrapper">
          <div className="logoforget">
            <img src={ForasLogo} />
          </div>
          <form onSubmit={handlePasswordSubmit} id = "resetPassForm">
            <input id="pass" type="password" placeholder="New Password" onChange={(e) => checkPassStrength(e, setStrength)} />
            <div style = {{marginTop:40,}}>
            <PasswordStrengthIndicator strength={strength} />
            </div>
            {loading ? <Loading /> : <button id = 'resetbutton' type="submit" >Submit</button>}
          </form>
          {error && <div>{error}</div>}
        </div>
      </div>
    </>
  )
}